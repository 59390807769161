import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Footer } from '../components/Footer';
import { PageFormCreateGroup } from '../pages/PageFormCreateGroup';
import { NotFound } from '../pages/NotFound';
import { Groups } from '../pages/Groups';
import { FormRegisterUser } from '../containers/FormRegisterUser';
import { DashboardUser } from '../components/DashboardUser';
import { HomeInfoAboutApuestaco } from '../components/HomeInfoAboutApuestaco';
// import { FixtureBets } from '../pages/FixtureBets';
import { Navbar } from '../containers/Navbar';
// import { AlertUserSessionExpiredToken } from '../components/AlertUserSessionExpiredToken';
import { PlayerBets } from '../components/PlayerBets';
import '../styles/Main.scss';
import { FormRecoverPassword } from '../containers/FormRecoverPassword';
import { JoinGroupComponent } from '../containers/JoinGroupComponent';
import { FixturesPage } from '../pages/Fixtures/FixturesPage';
import EditFixturePage from '../pages/Fixtures/EditFixturePage';
import { ToastContainer } from 'react-toastify';

function AppUI() {
  return (
    <React.Fragment>
      <ToastContainer></ToastContainer>
      <HashRouter>
        <Navbar />
        {/* <AlertUserSessionExpiredToken /> */}
        <Routes>
          <Route path='/' element={<HomeInfoAboutApuestaco />} />
          <Route path='/dashboard' element={<DashboardUser />} />
          <Route path='/register' element={<FormRegisterUser />} />
          <Route path='/recover' element={<FormRecoverPassword />} />
          <Route path='/create-new-group' element={<PageFormCreateGroup />} />
          <Route path='/edit-group/:id' element={<PageFormCreateGroup />} />
          <Route path='/groups' element={<Groups />} />
          <Route path='/fixtures' element={<FixturesPage />} />
          <Route path='/fixtures/:identifier' element={<EditFixturePage />} />
          <Route path='/groups/join' element={<JoinGroupComponent />} />
          {/* <Route path='/fixture-bets' element={<FixtureBets />} /> */}
          <Route path='/players/:playerIdentifier/groupbets/:groupIdentifier' element={<PlayerBets />} />
          <Route path='/*' element={<NotFound />} />
        </Routes>
        <Footer />
      </HashRouter>
    </React.Fragment>
  )
}

export { AppUI };