import React from "react";
import { AppUI } from "./AppUI.js";
import { AppProvider } from "../context";
import { GroupProvider } from "../context/groupContext";
import { UserProvider } from "../context/userContext";
import { BetsProvider } from "../context/betsContext";

function App() {
	return (
		<BetsProvider>
			<UserProvider>
				<GroupProvider>
					<AppProvider>
						<AppUI />
					</AppProvider>
				</GroupProvider>
			</UserProvider>
		</BetsProvider>
	);
}

export { App };
