import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { AlertDataRequiredInputs } from "../components/AlertDataRequiredInputs";
import { AlertCardDataRequired } from "../components/AlertCardDataRequired";
import { NavLink, useNavigate } from "react-router-dom";
import { useUser } from "../context/userContext";
import { useAppContext } from "../context";

function Login() {
	const { setLoadingLogin, loginUser, loadingLogin } = useUser();

	const { handleClickModalLogin, modalLogin } = useAppContext();

	const navigate = useNavigate();

	const alertRequiredDataDescription = {
		login: "Porfavor completar todos los campos, para ingresar",
		settingEmail: "Falta rellenar el email",
		wrongEmail: "El formato del email es incorrecto",
		settingPassword: "Falta rellenar la contraseña",
		wrognPassword: "La contraseña debe tener al menos 8 caracteres",
	};

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();
	const [messageTryToLogin, setMessageTryToLogin] = useState("");

	const onSubmit = (data) => {
		loginUser(data);
		const catchRequest = loginUser(data);		
		catchRequest.then((res) => {			
			if (res.StatusCode === 200) {
				setMessageTryToLogin("");
				handleClickModalLogin();
				setLoadingLogin(false);
				navigate("/groups");
				window.location.reload(); // we need reload after the token expired and the user reLogin
			}
			if (res.StatusCode === 400) {
				catchRequest.then((res) => setMessageTryToLogin(res.Errors[0]));
				if (messageTryToLogin) {
					setMessageTryToLogin("");
				} //reseting the info Card about create user or not
				setLoadingLogin(false);
			}
		});
		// setChekingInfoLocalStorage(checkingLocalStorage)
	};

	return (
		<React.Fragment>
			{modalLogin && (
				<div className='modal-login'>
					<div className='card card-container_form_login card-modal'>
						<div className='card-body overflow-auto container-modal-content_login'>
							<div className='d-flex justify-content-end'>
								<button
									type='button'
									className='btn-close'
									aria-label='Close'
									onClick={handleClickModalLogin}
								></button>
							</div>
							<h2 className='text-center title-login'>ApuestaCo</h2>
							<form
								className='container-form_login p-4'
								onSubmit={handleSubmit(onSubmit)}
							>
								<div className='mb-3'>
									<label htmlFor='email-login' className='form-label'>
										Correo electronico
									</label>
									<input
										type='email'
										className='form-control input-email_login'
										id='email-login'
										aria-describedby='emailHelp'
										{...register("username", {
											required: true,
											pattern: /\S+@\S+\.\S+/,
										})}
									/>
									{errors.username?.type === "required" && (
										<AlertDataRequiredInputs>
											{alertRequiredDataDescription.settingEmail}
										</AlertDataRequiredInputs>
									)}
									{errors.username?.type === "pattern" && (
										<AlertDataRequiredInputs>
											{alertRequiredDataDescription.wrongEmail}
										</AlertDataRequiredInputs>
									)}
								</div>
								<div className='mb-3'>
									<label htmlFor='password-login' className='form-label'>
										Contraseña
									</label>
									<input
										type='password'
										className='form-control input-password_login'
										id='password-login'
										{...register("password", {
											required: true,
										})}
									/>
									{errors.password?.type === "required" && (
										<AlertDataRequiredInputs>
											{alertRequiredDataDescription.settingPassword}
										</AlertDataRequiredInputs>
									)}
								</div>

								{errors.password?.type === "required" && (
									<AlertCardDataRequired>
										{alertRequiredDataDescription.login}
									</AlertCardDataRequired>
								)}
								<div className='mb-4 form-check'>
									<input
										type='checkbox'
										className='form-check-input input-check_login'
										id='check-hold_login'
										// {...register('keepSesion')} will active, is the bakend requiered
									/>
									<label
										className='form-check-label'
										htmlFor='check-hold_login'
									>
										¿Mantener sesión?
									</label>
								</div>

								{messageTryToLogin && (
									<AlertCardDataRequired>
										{messageTryToLogin}
									</AlertCardDataRequired>
								)}

								{loadingLogin ? (
									<div className='container-button_login d-flex justify-content-center'>
										<button
											type='submit'
											className='btn btn-primary btn-login_form'
											disabled={true}
										>
											<span
												className='spinner-border spinner-border-sm'
												role='status'
												aria-hidden='true'
											></span>
											<span className='mx-2'>Iniciando...</span>
										</button>
									</div>
								) : (
									<div className='container-button_login d-flex justify-content-center'>
										<button
											type='submit'
											className='btn btn-primary btn-login_form'
											disabled={false}
										>
											Iniciar Sesión
										</button>
									</div>
								)}
							</form>

							<div className='container-buttons_navlinks_login d-flex justify-content-around p-1'>
								<NavLink
									className='link-login_forgot_password text-center'
									onClick={handleClickModalLogin}
									to='/recover'
								>
									¿Olvidaste la constraseña?
								</NavLink>
								<NavLink
									className='link-login_create_account text-center'
									onClick={handleClickModalLogin}
									to='/register'
								>
									Crear una nueva cuenta
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
}

export { Login };
