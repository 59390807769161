import { useEffect } from "react";
import React, { useState } from "react";
import PlayerLeagueBet from "../../components/LeagueBets/PlayerLeagueBet";
import { getLeagueTeams } from "../../api/leagues";

const PlayerLeagueBets = ({playerLeagueBets, showLeagueInfo}) => {
    const [leagueBets, setLeagueBets] = useState(playerLeagueBets);
    const [league, setLeague] = useState(leagueBets ? leagueBets[0].league : null);
    const [groupLeagueSettings, setGroupLeagueSettings] = useState(leagueBets ? leagueBets[0].groupLeagueSetting : null);
    const [teams, setTeams] = useState();
    const [loading , setLoading] = useState(true);
    

    useEffect(() => {

        const getTeams = async() =>{
            setLoading(true);
            const teamsData = await getLeagueTeams(league.uniqueIdentifier);

            if(teamsData.data.Result){
                setTeams(teamsData.data.Result);
            }

            setLoading(false);
        };

        getTeams();

    }, []);

    const onBetChange = async (teamId) =>{
        alert(teamId);

    };

    if(!leagueBets){
        return (<>No hay pronósticos disponibles</>)
    }

    return (
        <div className="row mb-3">
        <div className=' align-items-center text-center' key={league.uniqueIdentifier}>
            <div><img height={40} src={league.logoUrl}></img></div>
            <strong>{league.name}</strong>
		    <p>
	    		<small>Pronósticos disponibles hasta&nbsp; {new Date(groupLeagueSettings.lockDate).toLocaleString()}</small>
			</p>
		</div>
        {leagueBets?.map((data, index) => (
            
            <PlayerLeagueBet key={`key league ${index}`}  data={data} teams={teams} onChange={onBetChange}></PlayerLeagueBet>					
		))}
        </div>        
    );
}

export default PlayerLeagueBets;