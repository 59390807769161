import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import EditFixture from "../../components/Fixtures/EditFixture";
import { getFixture } from "../../api/fixtures";

const EditFixturePage = ()=>{

    const params = useParams();
    const navigate = useNavigate();
    const [fixture, setFixture] = useState();

	useEffect(() => {

        const getData = async ()=>{
            if (params.identifier) {

                const data = await getFixture(params.identifier);

                if(data && data.data?.Result){
                    setFixture(data.data.Result);
                }			
            }
        };

        getData();		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

    const onFixtureEdited = ()=>{
        navigate('/fixtures');
    }

    return (
        <>
            <h3>Editar partido</h3>
            <hr />
            {fixture &&
                <EditFixture fixture={fixture} onSave={onFixtureEdited} />
            }
        </>
    );
}

export default EditFixturePage;