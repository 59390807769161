import React from 'react';

function Footer() {
    return (
        <div className='container my-4'>
            <footer className='footer'>
                <div className='row text-center'>
                    <div className='col-12 col-lg-4 my-2 text-muted'>
                        © 2024  Apuestaco
                    </div>
                    <div className='col-12 col-lg-4 my-2 text-muted'>
                        Desarrollado por:
                        <p>Felipe Zapata Ramírez</p>
                    </div>
                    <div className='col-12  col-lg-4 my-2'>
                        <a className='link-privacy' href='#'>Privacy</a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export { Footer };