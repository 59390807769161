// src/components/Fixtures/FixtureFilters.js

import React, { useState, useEffect } from 'react';
import { getLeagueTeams, getLeagues } from '../../api/leagues';


const FixtureFilters = ({ onFilterChange }) => {
    const [leagues, setLeagues] = useState([]);
    const [teams, setTeams] = useState([]);
    const [filters, setFilters] = useState({
        leagueIdentifier: '',
        teamIdentifier: '',
        startDate: '',
        endDate: '',
        upcomingEvents: true
    });


    useEffect(() => {
        const fetchLeagues = async () => {
            const leaguesData = await getLeagues();
            setLeagues(leaguesData.data.Result);
        };
        fetchLeagues();
    }, []);

    useEffect(() => {
        if (filters?.leagueIdentifier) {
            const fetchTeams = async () => {
                const teamsData = await getLeagueTeams(filters.leagueIdentifier);
                setTeams(teamsData.data.Result);
            };
            fetchTeams();
        } else {
            setTeams([]);
        }
    }, [filters.leagueIdentifier]);

    useEffect(() => {
        onFilterChange(filters);
    }, [filters, onFilterChange]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    return (
        <div className="row m-4">
            <div className="form-group col-md-3 col-12">
                <label htmlFor="league">League</label>
                <select
                    className="form-control form-control-sm"      
                    name="leagueIdentifier"              
                    value={filters.leagueIdentifier}
                    onChange={handleChange}
                >
                    <option value="">Select a league</option>
                    {leagues?.map((league) => (
                        <option key={league.uniqueIdentifier} value={league.uniqueIdentifier}>
                            {league.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group col-md-3 col-12">
                <label htmlFor="team">Team</label>
                <select
                    className="form-control form-control-sm"
                    name="teamIdentifier"
                    value={filters.teamIdentifier}
                    onChange={handleChange}
                    disabled={!filters.leagueIdentifier}
                >
                    <option value="">Select a team</option>
                    {teams.map((team) => (
                        <option key={team.uniqueIdentifier} value={team.uniqueIdentifier}>
                            {team.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="form-group col-md-3 col-12">
                <label htmlFor="startDate">Start Date</label>
                <input
                    type="date"
                    className="form-control form-control-sm"
                    name="startDate"
                    value={filters.startDate}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group col-md-3 col-12">
                <label htmlFor="endDate">End Date</label>
                <input
                    type="date"
                    className="form-control form-control-sm"
                    name="endDate"
                    value={filters.endDate}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group col-md-3 col-12">
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name="upcomingEvents"
                        checked={filters.upcomingEvents}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor="futureEvents">
                        Only Future Events
                    </label>
                </div>
            </div>
        </div>
    );
};

export default FixtureFilters;
