import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import bootstrap from 'bootstrap';
import 'typeface-open-sans';
import 'react-toastify/dist/ReactToastify.css';
import 'animate.css';
import WOW from 'wowjs';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
