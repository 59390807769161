import React, { useEffect, useState } from "react";
import { getFixtures } from "../../api/fixtures";
import FixtureList from "../../components/Fixtures/FixtureList";
import FixtureFilters from "../../components/Fixtures/FixtureFilters";
import { toastError} from "../../helpers/notificationHelpers";

function FixturesPage() {

    const [fixtures, setFixtures] = useState([]);
    const [filters, setFilters] = useState();

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };


    useEffect(() => {
        
        const getData = async () =>{

            if(!filters){
                return;
            }

            try{
                const data = await getFixtures(filters);
                
                if(!data || data.status !== 200){
                    toastError(data.data?.Errors ? data.data.Errors[0] : "Ha ocurrido un error.");
                    return;
                }

                setFixtures(data.data.Result);

            }
            catch(error){
                toastError("error");
            }
            
        };

        getData();

    }, [filters]);

	return (
		<React.Fragment>
            <h4>Fixtures</h4>

            <FixtureFilters onFilterChange={handleFilterChange} />

            {fixtures &&
                <div className="row p-4">
                    <strong>Total: {fixtures?.length}</strong>
                    <FixtureList fixtures={fixtures}></FixtureList>
                </div>
                
            }		
		</React.Fragment>
	);
}

export { FixturesPage };
