import React from 'react';

function AlertCardDataRequired(props) {
  return (
    <div className="alert alert-danger d-flex align-items-center my-3 overflow-auto" role="alert">
      {props.children}
    </div>
  )
}

export { AlertCardDataRequired };