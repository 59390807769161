import React from "react";
import { AlertUserSessionExpiredToken } from "../components/AlertUserSessionExpiredToken";

function NotFound() {
	return (
		<React.Fragment>
			<AlertUserSessionExpiredToken />
			<div className='container container-notFound'>
				<div className='conteiner-text'>
					<h1 className='h1-notFound'>Not Found</h1>
					<p className='p-error'>Error 404</p>
				</div>
				<div className='container-img'>
					<img
						className='img-notFound'
						src='https://i.ibb.co/sWSjN7z/Untitled2.png'
						alt='Imagen de dirección no encontrada'
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

export { NotFound };
