import React from 'react'

function AlertDataRequiredInputs(props) {
  return (
    <div className="text-danger small w-100 mt-1" role="alert">
      {props.children}
    </div>
  )
}

export { AlertDataRequiredInputs }