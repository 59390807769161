import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { AlertDataRequiredInputs } from "../components/AlertDataRequiredInputs";
import { AlertCardDataRequired } from "../components/AlertCardDataRequired";
import { AlertCardSucessProcess } from "../components/AlertCardSucessProcess";
import { useUser } from "../context/userContext";

function FormRegisterUser() {
	const { registerUser, loadingCreatingNewUser } = useUser();

	const alertRequiredDataDescription = {
		createAccount: "Debe completar todos los campos para crear la cuenta",
		settingName: "Debe ingresar un nombre",
		settingMaxLength: "Máximo 20 caracteres",
		settingLastName: "Debe ingresar un apellido",
		settingEmail: "Debe ingresar un email",
		wrongEmail: "El formato del email es incorrecto",
		settingPassword: "Debe ingresar una contraseña",
		settingRepeatPassword: "Repite la contraseña",
		wrognPassword: "La contraseña debe tener al menos 8 caracteres",
		repeatWrognPassword: "Las contraseñas no coinciden",
	};

	const {
		register,
		formState: { errors },
		watch,
		handleSubmit,
	} = useForm();

	const validationSamePassword = watch("password_2") === watch("password");
	const [messageCreatingNewAccount, setMessageCreatingNewAccount] =
		useState("");

	const onSubmit = (data) => {
		delete data.password_2; // the API only Required one password
		const catchRequest = registerUser(data);
		catchRequest.then((res) => setMessageCreatingNewAccount(res));
		if (messageCreatingNewAccount) {
			setMessageCreatingNewAccount("");
		} //reseting the info Card about create user or not
	};
		
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-12 col-md-5'>
						<div className='card card-form_register_user card-body mb-0'>
							<div className='container-img_register d-flex justify-content-center'>
								<h2>Crea Tú Cuenta</h2>
							</div>
							{/* Verification name */}
							<div className='mb-3'>
								<label htmlFor='fisrt-name-register' className='form-label'>
									Nombre
								</label>
								<input
									type='text'
									className='form-control'
									id='fisrt-name-register'
									{...register("firstName", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.firstName?.type === "required" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.settingName}
									</AlertDataRequiredInputs>
								)}
								{errors.firstName?.type === "maxLength" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.settingMaxLength}
									</AlertDataRequiredInputs>
								)}
							</div>
							{/* Verification name */}

							{/* Verification Lastname */}
							<div className='mb-3'>
								<label htmlFor='last-name' className='form-label'>
									Apellido
								</label>
								<input
									type='text'
									className='form-control'
									id='last-name'
									{...register("lastName", {
										required: true,
										maxLength: 20,
									})}
								/>
								{errors.lastName?.type === "required" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.settingLastName}
									</AlertDataRequiredInputs>
								)}
								{errors.lastName?.type === "maxLength" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.settingMaxLength}
									</AlertDataRequiredInputs>
								)}
							</div>
							{/* Verification Lastname */}

							{/* Verification Email */}
							<div className='mb-3'>
								<label htmlFor='email-register' className='form-label'>
									Correo electrónico
								</label>
								<input
									type='email'
									className='form-control'
									id='email-register'
									aria-describedby='emailHelp'
									{...register("emailAddress", {
										required: true,
										pattern: /\S+@\S+\.\S+/,
									})}
								/>
								{errors.emailAddress?.type === "required" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.settingEmail}
									</AlertDataRequiredInputs>
								)}
								{errors.emailAddress?.type === "pattern" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.wrongEmail}
									</AlertDataRequiredInputs>
								)}
							</div>
							{/* Verification Email */}

							{/* Verification Password */}
							{/* Verification Password-1 */}
							<div className='mb-3'>
								<label htmlFor='password-register' className='form-label'>
									Constraseña
								</label>
								<input
									name='password'
									type='password'
									className='form-control'
									id='password-register'
									{...register("password", {
										required: true,
										minLength: 8,
									})}
								/>
								{errors.password?.type === "required" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.settingPassword}
									</AlertDataRequiredInputs>
								)}
								{errors.password?.type === "minLength" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.wrognPassword}
									</AlertDataRequiredInputs>
								)}
							</div>
							{/* Verification Password-1 */}

							{/* Verification Password-2 Repeat */}
							<div className='mb-3'>
								<label htmlFor='password-register' className='form-label'>
									Escribe nuevamente la constraseña
								</label>
								<input
									name='password-repeat'
									type='password'
									className='form-control'
									id='password-register'
									{...register("password_2", {
										required: true,
									})}
								/>
								{errors.password_2?.type === "required" && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.settingRepeatPassword}
									</AlertDataRequiredInputs>
								)}
								{!validationSamePassword && (
									<AlertDataRequiredInputs>
										{alertRequiredDataDescription.repeatWrognPassword}
									</AlertDataRequiredInputs>
								)}
							</div>
							{/* Verification Password-2 Repeat */}

							{/* Verification Password */}

							{errors.password?.type === "required" && (
								<AlertCardDataRequired>
									{alertRequiredDataDescription.createAccount}
								</AlertCardDataRequired>
							)}

							{loadingCreatingNewUser ? (
								<Fragment>
									<button
										className='btn btn-primary btn-create_account'
										type='submit'
										disabled={true}
									>
										<span
											className='spinner-border spinner-border-sm'
											role='status'
											aria-hidden='true'
										></span>
										<span className='mx-2'>Creando...</span>
									</button>
								</Fragment>
							) : (
								<Fragment>
									<button
										className='btn btn-primary btn-create_account'
										type='submit'
										disabled={false}
									>
										Crear
									</button>
								</Fragment>
							)}
							{messageCreatingNewAccount &&
								(messageCreatingNewAccount === "Se creó exitosamente" ? (
									<AlertCardSucessProcess>
										{messageCreatingNewAccount}
									</AlertCardSucessProcess>
								) : (
									<AlertCardDataRequired>
										{messageCreatingNewAccount}
									</AlertCardDataRequired>
								))}
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}

export { FormRegisterUser };
