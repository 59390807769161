import { apiInstance } from "../apiInstance";

export const getTypesOfBites = async () => {
	const res = await apiInstance.get("bettypes/fixture");
	return res;
};

export const postUpdateFixtureBet = async (infoBet) => {
	const res = await apiInstance.post("fixturebets", infoBet);
	return res;
};

export const updatePlayerLeagueBet = async (leagueBet) => {
	const res = await apiInstance.post("leaguebets", leagueBet);
	return res;
};

export const getPlayerClosedBets = async (
	playerIdentifier,
	groupIdentifier
) => {
	const res = await apiInstance.get(
		`players/${playerIdentifier}/closedbets${
			groupIdentifier ? `?groupIdentifier=${groupIdentifier}` : ""
		}`
	);
	return res;
};

export const getPlayerBets = async () => {
	const res = await apiInstance.get("players/self/fixturebets");
	return res;
};

export const getMyLeagueBets = async (
	uniqueIdentifierGroupSelected
) => {
	const res = await apiInstance.get(
		`players/self/leaguebets?GroupIdentifier=${uniqueIdentifierGroupSelected}`
	);
	return res;
};