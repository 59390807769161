export function sortByDate(a, b) {
  //chronologically by year, month, day
  return new Date(a.fixture.date).valueOf() - new Date(b.fixture.date).valueOf();// getting inmediatly the value
}

export function formatDate(arrayDates){

  arrayDates.Result?.sort(sortByDate) // Sorted Dates

  const dates = arrayDates.Result?.map(item => {
    return new Date(item.fixture.date) // here we get the local date from User in GMT
  })  

  let ampm;

  const hours = dates?.map(date => date.getHours())
  const minutes = dates?.map(date => date.getMinutes())
  const daysDate = dates?.map(date => date.getDate())
  const days = dates?.map(date => date.getDay())
  const months = dates?.map(date => date.getMonth())
  const years = dates?.map(date => date.getFullYear())

  const daysWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  const monthsYear = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
    "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  // < formating from 24h to 12h>
  hours?.map((hour, index) => {
    if (hour >= 12) { // if 19 - 12 so 7 pm AND if 0 in format 24h so 12 am
      hours[index] = hour - 12;
      ampm = "PM"
    } else {
      ampm = "AM"
    }
  })

  hours?.map((hour, index) => {
    if (hour === 0) { // if 0 in format 24h so 12 am
      hours[index] = 12;
    }
  })

  minutes?.map((minute, index) => {
    if (minute < 10) { // if minutes too in format 24h is 0 so we add other 0
      minutes[index] = "0" + minute;
    }
  })

  const days2 = days?.map((day) => { //here only change the number for the necessary name week
    let arrayDays = [];
    arrayDays.push(daysWeek[day])    
    return arrayDays
  })
  

  months?.map((month, index) => { //here only change the number for the necessary name month
    months[index] = monthsYear[month];
  })
  // </ formating from 24h to 12h>

  return {
    dates,
    hours, 
    minutes, 
    daysDate, 
    // days, 
    days2,
    months, 
    years, 
    ampm,
  }
} 

export const joingDate = (arrayDays, arrayDaysDate, arrayMonths, arrayYears, arrayHours, arrayMinutes, countingDatas, ampmData) => {
  let stringWithDateInfo = [];
  for (let i = 0; i < countingDatas.length; i++) {

    const joinDay = arrayDays.map(day => day)
    const joinDayDates = arrayDaysDate.map(daysDate => daysDate)
    const joinMonths = arrayMonths.map(month => month)
    const joinYears = arrayYears.map(year => year)
    const joinHours = arrayHours.map(hour => hour)
    const joinMinutes = arrayMinutes.map(minute => minute)
    const ampmDataNew = countingDatas[i].getHours() >= 12 ? 'p.m.' : 'a.m.';
    
    stringWithDateInfo.push(`${joinDay[i]}, ${joinDayDates[i]} de ${joinMonths[i]} del ${joinYears[i]}, ${joinHours[i]}:${joinMinutes[i]} ${ampmDataNew}`)
    
  }
  return stringWithDateInfo // in stringWithDateInfo is all the information that we require
}