import { createContext, useState, useContext } from "react";
import {
	putEditGroup,
	postLeaveGroup,
	postJoinGroup,
	postCreateGroup,
	getGroupsUserSubscribed,
	getNextGroupEvent,
	getGroupTableStandings,
} from "../api/group";

export const GroupContext = createContext();

export const useGroup = () => {
	const context = useContext(GroupContext);
	return context;
};

export const GroupProvider = ({ children }) => {
	const [loadingEditingGroup, setLoadingEditingGroup] = useState(false);
	const [loadingLeaveGroup, setLoadingLeavegroup] = useState(false);
	const [loadingJoinGroup, setLoadingJoinGroup] = useState(false);
	const [loadingCreateNewGroup, setLoadingCreateNewGroup] = useState(false);

	const [infoGroupsUserSubscribed, setInfoGroupsUserSubscribed] = useState({});
	const [loadingGroupsSubscribed, setLoadingGroupsSubscribed] = useState(true);

	const [infoGroupNextEvent, setInfoGroupNextEvent] = useState({});
	const [loadingInfoGroupNextEvent, setLoadingInfoGroupNextEvent] =
		useState(true);

	const [infoGroupTableStandings, setInfoGroupTableStandings] = useState({});
	const [loadingInfoGroupTableStandings, setLoadInginfoGroupTableStandings] =
		useState(true);

	const getInfoGroupTableStandings = async (groupUniqueIdentifier) => {
		setLoadInginfoGroupTableStandings(true);
		try {
			const res = await getGroupTableStandings(groupUniqueIdentifier);
			setInfoGroupTableStandings(res.data);
			setLoadInginfoGroupTableStandings(false);
		} catch (error) {
			setLoadInginfoGroupTableStandings(false);
			console.error(error.message);
		}
	};

	const getInfoNextGroupEvent = async (groupUniqueIdentifier) => {
		setLoadingInfoGroupNextEvent(true);
		try {
			const res = await getNextGroupEvent(groupUniqueIdentifier);
			setInfoGroupNextEvent(res.data);
			setLoadingInfoGroupNextEvent(false);
		} catch (error) {
			setLoadingInfoGroupNextEvent(false);
			console.error(error.message);
		}
	};

	const getInfoGroupsSubscribed = async () => {
		setLoadingGroupsSubscribed(true);
		try {
			const res = await getGroupsUserSubscribed();
			setInfoGroupsUserSubscribed(res.data);
			setLoadingGroupsSubscribed(false);
		} catch (error) {
			setLoadingGroupsSubscribed(false);
			console.error(error.message);
		}
	};

	const createGroup = async (groupData) => {
		setLoadingCreateNewGroup(true);
		try {
			const res = await postCreateGroup(groupData);
			setLoadingCreateNewGroup(false);
		} catch (error) {
			console.error(error.message);
			setLoadingCreateNewGroup(false);
		}
	};

	const editGroup = async (groupIdentifier, dataGroupEdited) => {
		setLoadingEditingGroup(true);
		try {
			const res = await putEditGroup(groupIdentifier, dataGroupEdited);
			setLoadingEditingGroup(false);
		} catch (error) {
			setLoadingEditingGroup(false);
			console.error(error);
		}
	};

	const joinGroup = async (data) => {
		setLoadingJoinGroup(true);
		try {
			const res = await postJoinGroup(data);
			setLoadingJoinGroup(false);
			if (res.data.StatusCode === 400) {
				return res.data.Errors[0];
			}
			return res.data;
		} catch (error) {
			setLoadingJoinGroup(false);
			console.error(error);
		}
	};

	const leaveGroup = async (uniqueIdentifier) => {
		setLoadingLeavegroup(true);
		try {
			const res = await postLeaveGroup(uniqueIdentifier);
			setLoadingLeavegroup(false);
			if (res.data.StatusCode === 400) {
				return res.data;
			}
			return res.data;
		} catch (error) {
			console.error(error);
			setLoadingLeavegroup(false);
		}
	};

	return (
		<GroupContext.Provider
			value={{
				editGroup,
				loadingEditingGroup,
				joinGroup,
				loadingJoinGroup,
				createGroup,
				loadingCreateNewGroup,
				leaveGroup,

				getInfoGroupsSubscribed,
				infoGroupsUserSubscribed,
				loadingGroupsSubscribed,

				getInfoNextGroupEvent,
				infoGroupNextEvent,
				loadingInfoGroupNextEvent,

				getInfoGroupTableStandings,
				infoGroupTableStandings,
				loadingInfoGroupTableStandings,
			}}
		>
			{children}
		</GroupContext.Provider>
	);
};
