import { createContext, useState, useContext, useEffect } from "react";
import {
	getTypesOfBites,
	postUpdateFixtureBet,
	updatePlayerLeagueBet,
	getPlayerClosedBets,
	getPlayerBets,
	getMyLeagueBets,
} from "../api/bets";
import { formatDate, joingDate } from "../helpers";
import { getLeagueTeams, getLeagues } from "../api/leagues";

export const BetsContext = createContext();

export const useBets = () => {
	const context = useContext(BetsContext);
	return context;
};

export const BetsProvider = ({ children }) => {
	const [infoActivesChampionships, setInfoActivesChampionships] = useState({});
	const [loadingActivesChampionships, setLoadingActivesChampionships] =
		useState(true);

	const [infoTypesOfBites, setInfoTypesOfBites] = useState({});
	const [loadingTypesOfBites, setLoadingTypesOfBites] = useState(true);

	const [infoLeagueTeams, setInfoLeagueTeams] = useState({});

	const [loadingUpdateLegueBets, setLoadingUpdateLegueBets] = useState(false);

	const [infoPlayerClosedBets, setInfoPlayerClosedBets] = useState({});

	const [infoPlayerBets, setInfoPlayerBets] = useState({});
	const [dateMatchs, setDateMatchs] = useState([]);

	const [infoLeagueTeamSelected, setInfoLeagueTeamSelected] = useState({});


	const getInfoLeagueTeamSelectedForLeagueBets = async (
		uniqueIdentifierGroupSelected
	) => {
		try {
			const res = await getMyLeagueBets(
				uniqueIdentifierGroupSelected
			);

			const initialValueLeagueBet = {
				playerIdentifier: "",
				leagueIdentifier: "",
				groupIdentifier: "",
				success: null,
				betTypeId: null,
				playerBet: "",
				earnedPoints: null,
			};

			res.data.Result.forEach((data) => {
				if (!data.bet) data.bet = initialValueLeagueBet;
			});

			setInfoLeagueTeamSelected(res.data);
		} catch (error) {
			console.error(error.message);
		}
	};

	const getInfoPlayerBets = async () => {
		try {
			const res = await getPlayerBets();
			setInfoPlayerBets(res.data);
		} catch (error) {
			console.error(error.message);
		}
	};

	const { dates, hours, minutes, daysDate, days2, months, years, ampm } =
		formatDate(infoPlayerBets);

	useEffect(() => {
		if (dates) {
			setDateMatchs(
				joingDate(days2, daysDate, months, years, hours, minutes, dates, ampm)
			);
		}
	}, [infoPlayerBets]);

	const getInfoPlayerClosedBets = async (playerIdentifier, groupIdentifier) => {
		try {
			const res = await getPlayerClosedBets(playerIdentifier, groupIdentifier);
			setInfoPlayerClosedBets(res.data);
		} catch (error) {
			console.error(error.message);
			// if (error.response.status === 401) {
			// 	setCatchingError401ExpiredToken("no token");
			// }
		}
	};

	const updateLegueBets = async (leagueBet) => {
		setLoadingUpdateLegueBets(true);
		try {
			const res = await updatePlayerLeagueBet(leagueBet);
			setLoadingUpdateLegueBets(false);
			if (res.data.StatusCode === 400) {
				return res.data.Errors[0];
			}
			return res.data;
		} catch (error) {
			setLoadingUpdateLegueBets(false);
			console.error(error.message);
		}
	};

	const getInfoLeagueTeamsForLeagueBets = async (leagueIdentifier) => {
		try {
			const res = await getLeagueTeams(leagueIdentifier);
			setInfoLeagueTeams(res.data);
		} catch (error) {
			console.error(error.message);
		}
	};

	const updateFixtureBet = async (infoBet) => {
		setLoadingTypesOfBites(true);
		try {
			const res = await postUpdateFixtureBet(infoBet);
			return res.data;
		} catch (error) {
			console.error(error.message);
		}
	};

	const getInfoTypesOfBites = async () => {
		setLoadingTypesOfBites(true);
		try {
			const res = await getTypesOfBites();
			setInfoTypesOfBites(res.data);
			setLoadingTypesOfBites(false);
		} catch (error) {
			setLoadingTypesOfBites(false);
			console.error(error.message);
		}
	};

	const getInfoActivesChampionships = async () => {
		setLoadingActivesChampionships(true);
		try {
			const res = await getLeagues({activesOnly:true});
			setInfoActivesChampionships(res.data);
			setLoadingActivesChampionships(false);
		} catch (error) {
			setLoadingActivesChampionships(false);
			console.error(error.message);
		}
	};

	return (
		<BetsContext.Provider
			value={{
				getInfoActivesChampionships,
				infoActivesChampionships,
				loadingActivesChampionships,

				getInfoTypesOfBites,
				infoTypesOfBites,
				loadingTypesOfBites,

				updateFixtureBet,

				getInfoLeagueTeamsForLeagueBets,
				infoLeagueTeams,

				updateLegueBets,
				loadingUpdateLegueBets,
				setLoadingUpdateLegueBets,

				getInfoPlayerClosedBets,
				infoPlayerClosedBets,

				infoPlayerBets,
				getInfoPlayerBets,
				dateMatchs,

				getInfoLeagueTeamSelectedForLeagueBets,
				infoLeagueTeamSelected,
			}}
		>
			{children}
		</BetsContext.Provider>
	);
};
