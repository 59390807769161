import React, { useEffect, useState } from "react";
import { getMyLeagueBets } from "../../api/bets";
import PlayerLeagueBets from "./PlayerLeagueBets";

const GroupLeagueBets = ({currentUniqueIdentifierSelected}) => {
    const [dataGroupedByLeague, setDataGroupedByLeague] = useState();

    useEffect(() => {
        const getData = async() => {
            const data = await getMyLeagueBets(currentUniqueIdentifierSelected);

            if(data.data.Result){
                setDataGroupedByLeague(groupedByLeague(data.data.Result));
            }
        };

        getData();

    },[currentUniqueIdentifierSelected]);    

    const groupedByLeague = (data) => data.reduce((acc, current) => {
        if(current.group.uniqueIdentifier !== currentUniqueIdentifierSelected){
            return null;
        }
        
        const leagueId = current.league.uniqueIdentifier;
      
        if (!acc[leagueId]) {
          acc[leagueId] = {
            league: current.league,
            items: []
          };
        }
      
        acc[leagueId].items.push(current);
        return acc;
      }, {});

      

    return (
        <div className='card-body'>
			<div className='container'>
				{dataGroupedByLeague && (
                    Object.keys(dataGroupedByLeague).map(leagueId => {
                        let data = dataGroupedByLeague[leagueId];
                        return <PlayerLeagueBets key={leagueId} playerLeagueBets={data.items}></PlayerLeagueBets>
                }))}
			</div>
		</div>
    );

}

export default GroupLeagueBets;