export function createQueryString(params) {
    if(!params){
        return '';
    }
    
    const queryParams = new URLSearchParams();
    Object.keys(params).forEach(key => {
        if (params[key] !== '' && params[key] !== null && params[key] !== undefined) {
            queryParams.append(key, params[key]);
        }
    });
    return queryParams.toString();
}