import React, { useState, useEffect } from "react";
import { ModalFormCreateGroup } from "../components/ModalFormCreateGroup";
import { InfoCards } from "../components/InfoCards";
import { AlertUserSessionExpiredToken } from "../components/AlertUserSessionExpiredToken";
import { useParams } from "react-router-dom";

import { FormCreateGroup } from "../containers/FormCreateGroup";

function PageFormCreateGroup() {
	const params = useParams();

	useEffect(() => {
		if (params.id) {
			setModalInfoCreateNewGroup(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [modalInfoCreateNewGroup, setModalInfoCreateNewGroup] = useState(true);
	const handleClickModalInfoCreateNewGroup = () =>
		setModalInfoCreateNewGroup(!modalInfoCreateNewGroup);

	return (
		<React.Fragment>
			<AlertUserSessionExpiredToken />
			<div className='container'>
				<div className='row'>
					<div className='col-12 col-lg-6'>
						<div className='card card-form_create_group card-body card-createGroup'>
							<FormCreateGroup />
						</div>
					</div>
					<InfoCards />
				</div>
			</div>
			{modalInfoCreateNewGroup && (
				<ModalFormCreateGroup
					handleClickModalInfoCreateNewGroup={
						handleClickModalInfoCreateNewGroup
					}
				/>
			)}
		</React.Fragment>
	);
}

export { PageFormCreateGroup };
