import { apiInstance } from "../apiInstance";
import { createQueryString } from "../helpers/queryHelpers"

export const getFixtures = async (filters) => {
	let queryString = createQueryString(filters);	
	const res = await apiInstance.get(`fixtures?${queryString}`);
	return res;
};

export const getFixture = async (identifier) =>{
    const res = await apiInstance.get(`fixtures/${identifier}`);
	return res;
};

export const updateFixture = async (fixture) =>{
    const res = await apiInstance.put('fixtures', fixture);
	return res;
};