import { useEffect } from "react";
import { FaRegClipboard } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useGroup } from "../context/groupContext";

function CardInfoTableGroupSelected({ currentUniqueIdentifierSelected }) {
	const {
		getInfoGroupTableStandings,
		infoGroupTableStandings,
		loadingInfoGroupTableStandings,
	} = useGroup();

	useEffect(() => {
		getInfoGroupTableStandings(currentUniqueIdentifierSelected);
	}, [currentUniqueIdentifierSelected]);

	return (
		<table className='table table-striped'>
			<thead>
				<tr>
					<th scope='col'>#</th>
					<th></th>
					<th scope='col'>Nombre</th>
					<th scope='col' className='text-center'>
						Puntos
					</th>
					<th scope='col' className='text-center'>
						# Apuestas
					</th>
				</tr>
			</thead>
			<tbody>
				{loadingInfoGroupTableStandings ? (
					<tr className='spinner-border text-dark' role='status'>
						<td className='visually-hidden'>Cargando...</td>
					</tr>
				) : (
					infoGroupTableStandings.Result?.standings.map((item) => {
						let resultRank = "";
						if (item.previousRank > item.rank) {
							resultRank =
								"https://i.ibb.co/3ps26bk/Up-Arrow-removebg-preview.png";
						} else if (item.previousRank < item.rank) {							
							resultRank =
								"https://i.ibb.co/8NSHsFn/Down-Arrow-removebg-preview.png";
						} else {							
							resultRank =
								"https://i.ibb.co/fqsX9GN/Equal-Symbol-removebg-preview.png";
						}
						return (
							<tr key={item.playerIdentifier}>
								<th className='th-container_rank' scope='row'>
									{item.rank}
								</th>
								<td className='container-img_rank'>
									<img className='img-rank' src={resultRank} alt='rank' />
								</td>
								<td className='text-align-start'>
									<Link
										to={`/players/${item.playerIdentifier}/groupbets/${currentUniqueIdentifierSelected}`}
									>
										<FaRegClipboard />
									</Link>
									&nbsp;&nbsp;{item.playerFullName}
								</td>
								<td className='text-center'>{item.points}</td>
								<td className='text-center'>
									{item.predictionCount}
									<br></br>
									<span
										style={{ backgroundColor: "red" }}
										className='badge badge-danger'
									>
										{item.livePredictionText}
									</span>
								</td>
							</tr>
						);
					})
				)}
			</tbody>
		</table>
	);
}

export { CardInfoTableGroupSelected };
