import React from "react";
import { Login } from "../containers/Login";
import { useAppContext } from "../context";

function DashboardUser() {
	const { loadingLogin } = useAppContext();

	const checkingLocalStorage = localStorage.getItem("userApuestaco");

	return (
		<React.Fragment>
			{!loadingLogin && checkingLocalStorage ? (
				<div>
					Bienvenido a ApuestaCo
					<Login />
				</div>
			) : (
				<div>Debes iniciar sesión para poder ver tus pronósticos</div>
			)}
		</React.Fragment>
	);
}

export { DashboardUser };
