import React, { useState } from "react";
import BetTypes from "../../constants/BetTypes";
import TeamSelect from "../Teams/TeamSelect";
import { Fragment } from "react";
import { updatePlayerLeagueBet } from "../../api/bets";

const PlayerLeagueBet = ({data, teams}) =>{
    const {betType, bet, league, group} = data;
    const [groupLeagueSettings, setGroupLeagueSettings] = useState(data?.groupLeagueSetting);
    const isLeagueCLosedForBets = groupLeagueSettings && new Date() > new Date(groupLeagueSettings.lockDate);
    const [loading, setLoading] = useState(false);

    const onBetChange = async (teamId) => {
        const payload = {
			leagueIdentifier: league.uniqueIdentifier,
			groupIdentifier: group.uniqueIdentifier,
			betTypeId: betType.id,
			playerBet: teamId,
		};        
        
        setLoading(true);
        const res = await updatePlayerLeagueBet(payload);
        setLoading(false);			
		
        if (res.data.StatusCode === 400) {
				alert(res.data.Errors[0]);
		}
    };

    
        return (
            <Fragment>
			    <div className='row align-items-center p-2 mb-0'>
			    	<div className='col-12 col-lg-5 mb-0 text-right'>
			    		{data.betType.displayName} 
			    	</div>
			    	<div className='col-12 col-lg-7 mb-0'>
                        {(data.betType.id == BetTypes.Champion || data.betType.id == BetTypes.RunnerUp) &&
                            <TeamSelect teams={teams}
                            isDisabled={isLeagueCLosedForBets}
                            selectedTeamId={bet?.playerBet}
                            onChange={onBetChange}></TeamSelect>
                        }
                        {loading && 
                            <div className="spinner-grow spinner-grow-sm text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
			    	</div>
			    </div>
			    <div className='row mt-0 pt-0 mb-3'>
			    	<small className='col-12 col-lg-7 offset-lg-5'>
			    		{data.groupLeagueSetting.points} Pts en caso de acierto
			    	</small>
			    </div>
			</Fragment>           
        );

};

export default PlayerLeagueBet;