import React from 'react'

function AlertCardSucessProcess(props) {
  return (
    <div className="alert alert-success d-flex align-items-center my-3 overflow-auto" role="alert">
      {props.children}
    </div>
  )
}

export { AlertCardSucessProcess }