import React from "react";
import { SelectGroup } from "../containers/SelectGroup";
import { AlertUserSessionExpiredToken } from "../components/AlertUserSessionExpiredToken";

function Groups() {
	return (
		<React.Fragment>
			<AlertUserSessionExpiredToken />
			<SelectGroup />
		</React.Fragment>
	);
}

export { Groups };
