import React, { useState, useRef, useEffect } from "react";
import { AlertCardDataRequired } from "../components/AlertCardDataRequired";
import { AlertDataRequiredInputs } from "../components/AlertDataRequiredInputs";
import { SettingChampionship } from "./SettingChampionship";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import { useGroup } from "../context/groupContext";
import { useBets } from "../context/betsContext";
import { useAppContext } from "../context";

function FormCreateGroup() {
	const {
		getInfoActivesChampionships,
		infoActivesChampionships,
		loadingActivesChampionships,
		getInfoTypesOfBites,
		infoTypesOfBites,
		loadingTypesOfBites,
	} = useBets();

	const {
		loadingEditingGroup,
		editGroup,
		createGroup,
		loadingCreateNewGroup,
		getInfoGroupsSubscribed,
		infoGroupsUserSubscribed,
		loadingGroupsSubscribed,
	} = useGroup();

	const { toastMessage, messageError } = useAppContext();

	const [defaultValueToForm, setDefaultValueToForm] = useState({
		name: "",
		fixtureBetTypeId: "",
		leagueIdentifiers: "",
		fixtureBetTypeIdDisplayName: "",
		leagueIdentifiersDisplayName: "",
		selectLimitedDateToChampionship: "",
		allowLeagueBets: false,
		championBetPoints: "",
		runnerUpBetPoints: "",
	});

	const [successfulTextToast, setsuccessfulTextToast] = useState("");

	const params = useParams();

	const alertRequiredDataDescription = {
		createGroup: "Por favor completar todos los campos, para crear el grupo",
		createGroupWithSetting:
			"Por favor completar la configuración del pronóstico de campeonato",
		settingNameGroup: "Falta rellenar nombre",
		selectingChampionship: "Falta seleccionar un campeonato",
		selectingTypeOfBeat: "Falta seleccionar el tipo de apuesta",
		settingDate: "Falta establecer una fecha límite",
		settingPointChampion: "Falta rellenar el Pronóstico del campeón",
		settingPointSubchampion: "Falta rellenar el Pronóstico del subcampeón",
	};

	const divEventClickAllowLeagueBets = useRef();
	useEffect(() => {
		if (params.id && defaultValueToForm.allowLeagueBets) {
			divEventClickAllowLeagueBets.current.click();
		}
	}, [defaultValueToForm.allowLeagueBets]);

	const {
		register,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({
		defaultValues: defaultValueToForm,
	});

	const handleOnChangeForm = (e) => {
		if (e.target.name === "allowLeagueBets") {
			setDefaultValueToForm({
				...defaultValueToForm,
				[e.target.name]: e.target.checked,
			});
			return;
		}

		setDefaultValueToForm({
			...defaultValueToForm,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		getInfoGroupsSubscribed();
		getInfoActivesChampionships();
		getInfoTypesOfBites();
	}, []);

	useEffect(() => {
		if (params.id && infoGroupsUserSubscribed.Result?.length > 0) {
			const group = infoGroupsUserSubscribed.Result?.find(
				(group) => group.uniqueIdentifier === params.id
			);
			const nameGroup = group.name;

			const leagueIdentifiers = group.groupLeagues.map(
				(league) => league.league.uniqueIdentifier
			);

			const allowLeagueBets = group.groupLeagues.map(
				(league) => league.allowLeagueBets
			);

			const leagueBetDisplayName = group.groupLeagues.map(
				(league) => league.league.name
			);

			const leagueBetsDeadline = group.groupLeagues.map((league) =>
				league.groupLeagueSettings.map((setting) => setting.lockDate)
			);
			const deadlineFormated = (leagueBetDeadline) => {
				if (leagueBetDeadline[0].length > 0) {
					const deadline = leagueBetDeadline[0][0];
					const date1 = deadline.split("T");
					const deadlineFormated = date1[0];
					return deadlineFormated;
				}
				return null;
			};

			const leagueBetsPoints = group.groupLeagues.map((league) =>
				league.groupLeagueSettings.map((setting) => setting.points)
			);
			const pointsToChampion = leagueBetsPoints[0][0];
			const pointsToRunnerUp = leagueBetsPoints[0][1];

			const fixtureBetTypeId = group.fixtureBetTypeId;
			const fixtureBetTypeName = group.fixtureBetTypeName;
			if (params.id) {
				reset({
					name: nameGroup,
					fixtureBetTypeId,
					selectLimitedDateToChampionship: deadlineFormated(leagueBetsDeadline),
					allowLeagueBets: allowLeagueBets[0],
					championBetPoints: pointsToChampion,
					runnerUpBetPoints: pointsToRunnerUp,
					leagueIdentifiers: [leagueIdentifiers[0]],
				});

				setDefaultValueToForm({
					...defaultValueToForm,
					name: nameGroup,
					fixtureBetTypeId,
					fixtureBetTypeIdDisplayName: fixtureBetTypeName,
					leagueIdentifiersDisplayName: leagueBetDisplayName[0],
					leagueIdentifiers: leagueIdentifiers[0],
					allowLeagueBets: allowLeagueBets[0],
				});
			}
		}
	}, [loadingGroupsSubscribed]);

	const checkingLocalStorage = localStorage.getItem("userApuestaco");
	const tiggerToasMessage = () => {
		if (!loadingEditingGroup && !loadingCreateNewGroup) {
			if (messageError) {
				toastMessage(messageError, "error");
				setsuccessfulTextToast("");
			} else if (successfulTextToast) {
				toastMessage(successfulTextToast, "info");
			}
		}
	};
	useEffect(() => {
		tiggerToasMessage();
	}, [loadingEditingGroup, loadingCreateNewGroup]);

	const onSubmit = (data) => {
		const allowLeagueBets =
			data.allowLeagueBets === "true" || data.allowLeagueBets === true
				? true
				: false;

		const groupIdentifier = params.id;
		const isEditingGroup = params.id;
		if (isEditingGroup) {
			const dataEditedGroup = {
				groupIdentifier,
				name: data.name,
				leagueIdentifiers: data.leagueIdentifiers,
				allowLeagueBets,
				leagueBetsDeadline: null,
				championBetPoints: null,
				runnerUpBetPoints: null,
			};

			if (allowLeagueBets && data.selectLimitedDateToChampionship) {
				const creatingDate = new Date(data.selectLimitedDateToChampionship);
				let dataFormated = {
					...dataEditedGroup,
					leagueBetsDeadline: creatingDate?.toISOString(),
				};

				const dataEditedGroupFormated = {
					...dataEditedGroup,
					leagueBetsDeadline: dataFormated.leagueBetsDeadline,
					championBetPoints: data.championBetPoints,
					runnerUpBetPoints: data.runnerUpBetPoints,
				};
				if (checkingLocalStorage) {
					editGroup(groupIdentifier, dataEditedGroupFormated);
					setsuccessfulTextToast("Se han guardado los cambios");
				} else {
					alert("necesitas inisiar sesión!"); // necesito ubicar un toast como alerta aqui
				}
				return;
			}

			if (checkingLocalStorage) {
				editGroup(groupIdentifier, dataEditedGroup);
				setsuccessfulTextToast("Se han guardado los cambios");
			} else {
				alert("necesitas iniciar sesión!"); // necesito ubicar un toast como alerta aqui
			}
		} else {
			const allowLeagueBets =
				data.allowLeagueBets === "true" || data.allowLeagueBets === true
					? true
					: false;
			const dataCreateNewGroup = {
				allowLeagueBets,
				fixtureBetTypeId: data.fixtureBetTypeId,
				leagueIdentifiers: data.leagueIdentifiers,
				name: data.name,
				championBetPoints: null,
				runnerUpBetPoints: null,
				selectLimitedDateToChampionship: data.selectLimitedDateToChampionship,
			};

			if (allowLeagueBets && data.selectLimitedDateToChampionship) {
				const creatingDate = new Date(data.selectLimitedDateToChampionship);
				let dataFormated = {
					...dataCreateNewGroup,
					leagueBetsDeadline: creatingDate?.toISOString(),
					championBetPoints: data.championBetPoints,
					runnerUpBetPoints: data.runnerUpBetPoints,
				};

				if (checkingLocalStorage) {
					createGroup(dataFormated);
					setsuccessfulTextToast("Se ha creado el grupo");
				} else {
					alert("necesitas iniciar sesión!"); // necesito ubicar un toast como alerta aqui
				}
				return;
			}
			if (checkingLocalStorage) {
				createGroup(dataCreateNewGroup);
				setsuccessfulTextToast("Se ha creado el grupo");
			} else {
				alert("necesitas iniciar sesión!"); // necesito ubicar un toast como alerta aqui
			}
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='form-container'>
			{(messageError || successfulTextToast) && <ToastContainer limit={4} />}
			<h4>{params.id ? "Editando grupo" : "Crear competencia"}</h4>
			<div className='mb-3'>
				<label htmlFor='nameGroup' className='form-label'>
					Nombre
				</label>
				<input
					value={defaultValueToForm.name}
					type='text'
					className='form-control'
					id='nameGroup'
					aria-describedby='nameHelp'
					{...register("name", {
						required: true,
						onChange: handleOnChangeForm,
					})}
				/>
				{errors.name?.type === "required" && (
					<AlertDataRequiredInputs>
						{alertRequiredDataDescription.settingNameGroup}
					</AlertDataRequiredInputs>
				)}
			</div>

			<label htmlFor='typeOfChampionship' className='form-label mt-1'>
				Campeonatos
			</label>
			{loadingActivesChampionships ? (
				<div className='spinner-border text-dark' role='status'>
					<span className='visually-hidden'>Cargando...</span>
				</div>
			) : (
				<div className='input-group'>
					<select
						className='form-select'
						id='typeOfChampionship'
						aria-label='Example select with button addon'
						{...register("leagueIdentifiers.0", {
							required: true,
							onChange: handleOnChangeForm,
						})}
					>
						{params.id ? (
							<option value={defaultValueToForm.leagueIdentifiers}>
								{defaultValueToForm.leagueIdentifiersDisplayName}
							</option>
						) : (
							<option>Seleccionar...</option>
						)}
						{infoActivesChampionships.Result.map((item) => {
							return (
								<option
									key={item.uniqueIdentifier}
									value={item.uniqueIdentifier}
								>
									{item.name}
								</option>
							);
						})}
					</select>
					{errors.leagueIdentifiers &&
						errors.leagueIdentifiers[0].type === "required" && (
							<AlertDataRequiredInputs>
								{alertRequiredDataDescription.selectingChampionship}
							</AlertDataRequiredInputs>
						)}
				</div>
			)}

			<label htmlFor='typeOfBet' className='form-label mt-4'>
				Tipo de apuesta
			</label>
			{loadingTypesOfBites ? (
				<div className='spinner-border text-dark' role='status'>
					<span className='visually-hidden'>Cargando...</span>
				</div>
			) : (
				<div className='input-group'>
					<select
						disabled={params.id}
						className='form-select'
						id='typeOfBet'
						aria-label='Example select with button addon'
						{...register("fixtureBetTypeId", {
							required: true,
							onChange: handleOnChangeForm,
						})}
					>
						{params.id ? (
							<option value={defaultValueToForm.fixtureBetTypeId}>
								{defaultValueToForm.fixtureBetTypeIdDisplayName}
							</option>
						) : (
							<option>Seleccionar...</option>
						)}
						{infoTypesOfBites.Result.map((item) => {
							return (
								<option key={item.id} value={item.id}>
									{item.displayName}
								</option>
							);
						})}
					</select>
					{errors.fixtureBetTypeId?.type === "required" && (
						<AlertDataRequiredInputs>
							{alertRequiredDataDescription.selectingTypeOfBeat}
						</AlertDataRequiredInputs>
					)}
				</div>
			)}

			<div
				className='form-check my-4'
				data-bs-toggle='collapse'
				href='#collapseSettingChampionship'
				ref={divEventClickAllowLeagueBets}
			>
				<input
					className='form-check-input'
					type='checkbox'
					id='flexCheckDefault'
					checked={defaultValueToForm.allowLeagueBets}
					value={defaultValueToForm.allowLeagueBets}
					{...register("allowLeagueBets", {
						onChange: handleOnChangeForm,
					})}
				/>
				<label className='form-check-label w-100' htmlFor='flexCheckDefault'>
					¿Deseas permitir pronósticos de campeonato?
				</label>
			</div>

			<SettingChampionship
				register={register}
				valueAllowLeagueBets={defaultValueToForm.allowLeagueBets}
				errors={errors}
				alertRequiredDataDescription={alertRequiredDataDescription}
			/>

			<button
				type='submit'
				className='btn btn-primary button-form-create-new-group'
				disabled={loadingCreateNewGroup || loadingEditingGroup}
			>
				{loadingCreateNewGroup || loadingEditingGroup ? (
					<div className='spinner-border text-light' role='status'></div>
				) : (
					<>{params.id ? "Gurdar Cambios" : "Crear Grupo"}</>
				)}
			</button>

			{errors.fixtureBetTypeId?.type === "required" && (
				<AlertCardDataRequired>
					{alertRequiredDataDescription.createGroup}
				</AlertCardDataRequired>
			)}
		</form>
	);
}

export { FormCreateGroup };
