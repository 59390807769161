import React, { useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useBets } from "../context/betsContext";

function PlayerBets() {
	const { getInfoPlayerClosedBets, infoPlayerClosedBets } = useBets();

	const { playerIdentifier } = useParams();
	const { groupIdentifier } = useParams();

	useEffect(() => {
		getInfoPlayerClosedBets(playerIdentifier, groupIdentifier);
	}, [playerIdentifier]);

	let sortedFixtureBets = infoPlayerClosedBets?.Result?.fixtureBets.sort(
		(a, b) => {
			return new Date(a.fixture.date) > new Date(b.fixture.date)
				? -1
				: new Date(b.fixture.date) > new Date(a.fixture.date)
				? 1
				: 0;
		}
	);

	return (
		<div className='container'>
			{infoPlayerClosedBets?.Result?.leagueBets?.length > 0 && (
				<div className='card-body px-0 my-4 d-flex flex-column align-items-center'>
					<div className='card mx-5 card-container-info-selftFixtureBets'>
						<div className='card-header card-header-color text-center'>
							<strong>Pronósticos de campeonato</strong>
						</div>
						<div className='card-body'>
							{infoPlayerClosedBets.Result.leagueBets.map(
								(leagueBet, index) => {
									return (
										<Fragment key={`key league ${index}`}>
											<div className='row align-items-center p-2 mb-0'>
												<div className='col-12 col-lg-5 mb-0 text-right'>
													{leagueBet.betType.displayName}{" "}
													{leagueBet.league.name}
												</div>
												<div className='col-12 col-lg-7 mb-0 align-items-center d-flex flex-column'>
													<img
														className='img-logo_team_fixture_bets'
														src={leagueBet.bet?.team?.logoUrl}
														alt='logo'
													></img>
													<br></br>
													<span>{leagueBet.bet?.team?.name || "--"}</span>
													<div className='row mt-0 pt-0 mb-3'>
														<small
															className={` ${
																leagueBet.bet?.earnedPoints ? "" : "invisible"
															}`}
														>
															{" "}
															+ {leagueBet.bet?.earnedPoints} Pts
														</small>
													</div>
												</div>
											</div>
										</Fragment>
									);
								}
							)}
						</div>
					</div>
				</div>
			)}
			{sortedFixtureBets && (
				<div>
					{sortedFixtureBets.map((info, index) => {
						return (
							<Fragment key={`key sorted bets ${index}`}>
								<div className='card-body px-0 my-4 d-flex flex-column align-items-center'>
									<div
										className={`card pt-0 mx-5 card-container-info-selftFixtureBets ${
											info.bet?.earnedPoints ? "border-success" : ""
										} `}
									>
										<div
											className={`card-header ${
												info.bet?.earnedPoints ? "border-success" : ""
											} `}
										>
											+ {info.bet?.earnedPoints}
										</div>
										<div className='card-body px-0'>
											<div className='container'>
												<div className='row'>
													<div className='col-12 mb-2 text-center container-date-matchs pb-2'>
														{new Date(info.fixture.date).toLocaleString()}
													</div>

													<div className='col-4 d-flex flex-column align-items-center'>
														<div className='col'>
															<img
																className='img-logo_team_fixture_bets'
																src={info.fixture.homeTeam.logoUrl}
																alt='logo home team'
															/>
														</div>
													</div>

													<div className='col-4 d-flex flex-column align-items-center align-self-center'>
														<div className='row'>
															<div className='col'>
																{info.fixture.fullTimeHomeGoals}
															</div>
															-
															<div className='col'>
																{info.fixture.fullTimeAwayGoals}
															</div>
														</div>
														<div className='row'>
															<small className='text-muted'>
																Marcador final
															</small>
														</div>
													</div>

													<div className='col-4 d-flex flex-column align-items-center'>
														<div className='col'>
															<img
																className='img-logo_team_fixture_bets'
																src={info.fixture.awayTeam.logoUrl}
																alt='logo away team'
															/>
														</div>
													</div>
												</div>

												<div className='row align-items-center'>
													<div className='col-4 text-center'>
														<div className='d-flex flex-column align-items-center'>
															<label
																className='form-check-label label-input-fixture-bets-score my-2'
																htmlFor={`check-input-fixtures-bets-1-${info.fixture.uniqueIdentifier}`}
															>
																{info.fixture.homeTeam.name}
															</label>
															{info.group.fixtureBetTypeId === 2 ? (
																<div>
																	{info.bet?.homeTeamScore == undefined
																		? "--"
																		: info.bet.homeTeamScore}
																</div>
															) : (
																<input
																	checked={
																		info.bet?.playerBet === 1 ? true : false
																	}
																	className='form-check-input input-check-fixture-bets'
																	type='radio'
																	id='check-input-radio-bet-fixtures-1'
																	readOnly
																	disabled
																/>
															)}
														</div>
													</div>

													<div className='col-4 text-center'>
														{info.group.fixtureBetTypeId === 2 ? (
															<div></div>
														) : (
															<div className='d-flex flex-column align-items-center'>
																<label
																	className='form-check-label label-input-fixture-bets-score my-2'
																	htmlFor='check-input-radio-bet-fixtures-3'
																>
																	Empate
																</label>
																<input
																	checked={
																		info.bet?.playerBet === 3 ? true : false
																	}
																	className='form-check-input input-check-fixture-bets'
																	type='radio'
																	id='check-input-radio-bet-fixtures-3'
																	readOnly
																	disabled
																/>
															</div>
														)}
													</div>

													<div className='col-4 text-center'>
														<div className='d-flex flex-column align-items-center'>
															<label
																className='form-check-label label-input-fixture-bets-score my-2'
																htmlFor={`check-input-fixtures-bets-2-${info.fixture.uniqueIdentifier}`}
															>
																{info.fixture.awayTeam.name}
															</label>
															{info.group.fixtureBetTypeId === 2 ? (
																<div>
																	{info.bet?.awayTeamScore == undefined
																		? "--"
																		: info.bet.awayTeamScore}
																</div>
															) : (
																<input
																	checked={
																		info.bet?.playerBet === 2 ? true : false
																	}
																	className='form-check-input input-check-fixture-bets'
																	type='radio'
																	id='check-input-radio-bet-fixtures-2'
																	readOnly
																	disabled
																/>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Fragment>
						);
					})}
				</div>
			)}
		</div>
	);
}

export { PlayerBets };
