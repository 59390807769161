import React from "react";
import { Login } from "./Login";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppContext } from "../context";
import UserRoles from "../constants/UserRoles";


function Navbar() {
	const checkingLocalStorage = localStorage.getItem("userApuestaco");
	const parsedUserInfo = JSON.parse(checkingLocalStorage);
	const userRole = parsedUserInfo?.role;
	const navigate = useNavigate();

	const { setUserInfoLogin, handleClickModalLogin, setGetInfoCurrentBetsUser } =
		useAppContext();

	const handleLogout = () => {
		localStorage.removeItem("userApuestaco");
		setUserInfoLogin(null);
		setGetInfoCurrentBetsUser({});
		navigate("/");
	};

	return (
		<nav className='navbar navbar-expand-lg navbar-dark'>
			<div className='container-fluid'>
				<NavLink
					className='navbar-brand'
					to={checkingLocalStorage ? "/groups" : "/"}
				>
					<img
						src='https://i.ibb.co/WWt6rk8/logo-apuestaco.png'
						alt='logo de apuestaco'
						className=' img d-inline-block align-text-top'
					/>
					Apuestaco
				</NavLink>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<span className='navbar-toggler-icon'></span>
				</button>

				{checkingLocalStorage ? (
					// UI USER LOGIN
					<div className='collapse navbar-collapse' id='navbarSupportedContent'>
						<ul className='navbar-nav me-auto mb-2 mb-lg-0'>
							<li className='nav-item'>
								<NavLink className='nav-link' to='/groups'>
									Grupos
								</NavLink>
							</li>
							{
								userRole === UserRoles.AdminUser &&
								<div className='col-12 col-lg-4'>
								<ul className='navbar-nav me-auto mb-2 mb-lg-0 '>
									<li className='nav-item dropdown'>
										<a
											className='nav-link dropdown-toggle'
											href='#'
											id='navbarDropdown'
											role='button'
											data-bs-toggle='dropdown'
											aria-expanded='false'
										>
											Admin
										</a>
										<ul
											className='dropdown-menu'
											aria-labelledby='navbarDropdown'
										>
											<li className='nav-item'>
											<NavLink className='dropdown-item' to='/fixtures'>
												Partidos
											</NavLink>
											</li>
										</ul>
									</li>
								</ul>
							</div>									
							}
							
							
							{/* <li className='nav-item dropdown'>
								<a
									className='nav-link dropdown-toggle'
									href='#'
									id='navbarDropdown'
									role='button'
									data-bs-toggle='dropdown'
									aria-expanded='false'
								>
									Ayuda
								</a>
								<ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
									<li>
										<a className='dropdown-item' href='#'>
											Manual de usuario
										</a>
									</li>
									<li>
										<a className='dropdown-item' href='#'>
											Crear caso de soporte
										</a>
									</li>
								</ul>
							</li> */}
						</ul>

						<div className='container container-welcome-setting ml-0'>
							<div className='row '>
								<div className='col-12 col-lg-4'>
									<ul className='navbar-nav me-auto mb-2 mb-lg-0 '>
										<li className='nav-item dropdown'>
											<a
												className='nav-link dropdown-toggle'
												href='#'
												id='navbarDropdown'
												role='button'
												data-bs-toggle='dropdown'
												aria-expanded='false'
											>
												Ajustes
											</a>
											<ul
												className='dropdown-menu'
												aria-labelledby='navbarDropdown'
											>
												<li>
													<a
														onClick={handleLogout}
														className='dropdown-item'
														href='#'
													>
														Cerrar Sesión
													</a>
												</li>
											</ul>
										</li>
									</ul>
								</div>

								<div className='col-12 col-lg-8'>
									<ul className='navbar-nav me-auto mb-2 mb-lg-0'>
										<li className='nav-item nav-welcome'>
											<a
												className='nav-link'
												href='#'
											>{`Bienvenido ${parsedUserInfo?.firstName}`}</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				) : (
					// UI USER LOGOUT
					<div
						className='collapse navbar-collapse justify-content-end'
						id='navbarSupportedContent'
					>
						<ul className='navbar-nav me-auto mb-2 mb-lg-0'>
							<li className='nav-item pb-1'>
								<NavLink className='nav-link' aria-current='page' to='/#'>
									Inicio
								</NavLink>
							</li>
						</ul>
						<div className='row'>
							<div className='col'>
								<button
									className='btn btn-light btn-login'
									type='button'
									// data-bs-toggle="modal"
									// data-bs-target="#login-button"
									onClick={handleClickModalLogin}
								>
									Iniciar Sesión
								</button>
							</div>
							<div className='col'>
								<NavLink to='/register'>
									<button className='btn btn-light btn-register'>
										Registrarse
									</button>
								</NavLink>
							</div>
						</div>
					</div>
				)}
			</div>
			<Login />
		</nav>
	);
}

export { Navbar };
