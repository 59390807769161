import axios from "axios";
const checkingLocalStorage = localStorage.getItem('userApuestaco')
const parsedUserInfo = JSON.parse(checkingLocalStorage);
export const apiInstance = axios.create({
    //baseURL: 'https://fzapataramirez-002-site2.gtempurl.com/api/',
    baseURL: process.env.REACT_APP_API_URL,
})

apiInstance.interceptors.request.use(
    (request) => {
        request.headers['Authorization'] = `Bearer ${parsedUserInfo?.token}`
        return request;
    },
    (err) => console.error('Interceptor Token' + err)
)