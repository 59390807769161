

function ModalFormCreateGroup({ handleClickModalInfoCreateNewGroup }) {
    return (
        <div className="card card-modal" onClick={() => handleClickModalInfoCreateNewGroup()}>
            <div className="card-header">
                Introducción a los grupos de ApuestaCo
            </div>
            <div className="card-body">
                <p className="card-text">Para divertirte apostando puedes unirte o crear tus propios grupos.
                    A continuación encuentras una breve explicación sobre el manejo de estos:</p>
                <ul>
                    <li>Cada grupo se asocia a un torneo. Por ejemplo, simultáneamente se puede estar jugando la
                        copa América y la copa de Europa. Sin embargo el administrador del
                        grupo decidirá cuál de los torneos disponibles en ApuestaCo se utilizará para el grupo.
                    </li>
                    <br />
                    <li>Cada grupo tiene un código de acceso que debe ser utilizado por los demás jugadores al
                        momento de unirse. Cuándo se crea un grupo, se asigna dicho
                        código y el administrador es el encargado de darlo a conocer a los jugadores que desea unir al grupo.
                    </li>
                    <br />
                    <li>Como administrador de un grupo puedes unir
                        jugadores enviando el nombre del grupo y código de acceso a tus amigos o puedes utilizar el
                        sistema de notificaciones de ApuestaCo para enviar al correo elctrónico de cada persona los datos del grupo y
                        las instrucciones de como unirse (Esta opción se encuentra en la sección 'Administrar grupo').
                    </li>
                </ul>
                <div className="modal-footer">
                    <button
                        onClick={() => handleClickModalInfoCreateNewGroup()}
                        type="button"
                        className="btn btn-primary btn-modal-info-create-group">
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    )
}

export { ModalFormCreateGroup };