import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { AlertDataRequiredInputs } from "../components/AlertDataRequiredInputs";
import { AlertCardDataRequired } from "../components/AlertCardDataRequired";
import { AlertCardSucessProcess } from "../components/AlertCardSucessProcess";
import { useGroup } from "../context/groupContext";

function JoinGroupComponent() {
	const { joinGroup, loadingJoinGroup } = useGroup();

	const errorMessages = {
		accessCodeRequired: "Debe ingresar el código de acceso.",
		accessCodeInvalidLength:
			"El código de acceso no debe superar los 10 caracteres.",
	};
	const [apiResponse, setApiResponse] = useState(null);
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm();

	const onSubmit = (data) => {
		const catchRequest = joinGroup(data);
		catchRequest.then((res) => {
			setApiResponse(res);
		});
	};

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-md-5'>
							<div className='card card-form_register_user card-body mb-0'>
								<div className='container-img_register d-flex justify-content-center'>
									<h2>Unirme a un grupo privado</h2>
								</div>
								{/* Verification Access Code */}
								<div className='mb-3'>
									<label htmlFor='accessCode' className='form-label'>
										Código de acceso
									</label>
									<input
										type='text'
										maxLength={10}
										className='form-control'
										id='accessCode'
										aria-describedby='accessCodeHelp'
										{...register("accessCode", {
											required: true,
											maxLength: 10,
										})}
									/>
									{errors.accessCode?.type === "required" && (
										<AlertDataRequiredInputs>
											{errorMessages.accessCodeRequired}
										</AlertDataRequiredInputs>
									)}
									{errors.accessCode?.type === "maxLength" && (
										<AlertDataRequiredInputs>
											{errorMessages.accessCodeInvalidLength}
										</AlertDataRequiredInputs>
									)}
								</div>
								{/* Verification Access Code */}
								<Fragment>
									<button
										className='btn btn-primary btn-create_account'
										type='submit'
										disabled={loadingJoinGroup}
									>
										{loadingJoinGroup && (
											<span
												className='spinner-border spinner-border-sm'
												role='status'
												aria-hidden='true'
											></span>
										)}
										{loadingJoinGroup ? "Uniendo..." : "Unirme"}
									</button>
								</Fragment>

								{apiResponse &&
									(apiResponse?.IsSuccess ? (
										<AlertCardSucessProcess>
											Se ha unido correctamente al grupo.
										</AlertCardSucessProcess>
									) : (
										<AlertCardDataRequired>
											{apiResponse?.Errors[0]}
										</AlertCardDataRequired>
									))}
							</div>
						</div>
					</div>
				</div>
			</form>
		</React.Fragment>
	);
}

export { JoinGroupComponent };
