import React, { useState } from "react";

const TeamSelect = ({teams, selectedTeamId, onChange, isDisabled, }) => {
    const [selectedValue, setSelectedValue] = useState(selectedTeamId);
    

    const handleChange = async (event) =>{
        setSelectedValue(event.target.value);
        if(onChange){
            await onChange(event.target.value);
        }        
    }

    return (
        <div className="grid">
            <select
                    className='form-select form-select-sm mb-3'
                    aria-label='Selecciona un equipo'
                    placeholder="Selecciona un equipo"
                    onChange={handleChange}
                    disabled={isDisabled}
                    value={selectedValue}                    
                >
                    {!selectedTeamId &&
                        <option defaultValue>Seleccione un equipo</option>
                    }                    
                    {teams?.map((data) => (
                        <React.Fragment key={data.uniqueIdentifier}>
                            <option value={data.id} name={data.name}>
                                {data.name}
                            </option>
                        </React.Fragment>
                    ))}
                </select>
        </div>
    );

}

export default TeamSelect;