import { createContext, useState, useContext } from "react";
import {
	postRegisterUser,
	postLoginUser,
	postRecoverPassword,
	postChangePassword,
} from "../api/user";

export const UserContext = createContext();

export const useUser = () => {
	const context = useContext(UserContext);
	return context;
};

export const UserProvider = ({ children }) => {
	const [loadingCreatingNewUser, setLoadingCreatingNewUser] = useState(false);

	const [loadingLogin, setLoadingLogin] = useState(false);
	const [userInfoLogin, setUserInfoLogin] = useState(null); //logout button using setUserInfoLogin

	const [loadingRecoverPassword, setLoadingRecoverPassword] = useState(false);
	const [loadingChangePassword, setLoadingChangePassword] = useState(false);

	const changePassword = async (dataUser) => {
		setLoadingChangePassword(true);
		try {
			const res = await postChangePassword(dataUser);
			setLoadingChangePassword(false);
			if (res.data.StatusCode === 400) {
				return res.data.Errors[0];
			}
			return res.data;
		} catch (error) {
			console.error(error);
			setLoadingChangePassword(false);
		}
	};

	const recoverPassword = async (dataUser) => {
		setLoadingRecoverPassword(true);
		try {
			const res = await postRecoverPassword(dataUser);
			setLoadingRecoverPassword(false);
			if (res.data.StatusCode === 400) {
				return res.data.Errors[0];
			}
			return res.data;
		} catch (error) {
			console.error(error);
			setLoadingRecoverPassword(false);
		}
	};

	const loginUser = async (dataUser) => {
		setLoadingLogin(true);
		try {
			const res = await postLoginUser(dataUser);
			setUserInfoLogin(res.data.Result);
			return res.data;
		} catch (error) {
			console.error(error.response.status);
			return error.response.data;
		}
	};

	const registerUser = async (dataUser) => {
		setLoadingCreatingNewUser(true);
		try {
			const res = await postRegisterUser(dataUser);
			if (res.data.StatusCode === 200) {
				setLoadingCreatingNewUser(false);
				return "Se creó exitosamente";
			}
			if (res.data.StatusCode === 400) {
				setLoadingCreatingNewUser(false);
				return res.data.Errors[0];
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<UserContext.Provider
			value={{
				registerUser,
				loadingCreatingNewUser,

				loginUser,
				userInfoLogin,

				setUserInfoLogin,
				loadingLogin,				
				setLoadingLogin,

				recoverPassword,
				loadingRecoverPassword,
				
				changePassword,
				loadingChangePassword,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
