import { apiInstance } from "../apiInstance";

export const putEditGroup = async (groupIdentifier, dataGroupEdited) => {
	const res = await apiInstance.put(
		`groups/${groupIdentifier}`,
		dataGroupEdited
	);
	return res;
};

export const postLeaveGroup = async (uniqueIdentifier) => {
	const res = await apiInstance.post(`groups/${uniqueIdentifier}/leave`);
	return res;
};

export const postJoinGroup = async (data) => {
	const res = await apiInstance.post("groups/join", data);
	return res;
};

export const postCreateGroup = async (groupData) => {
	const res = await apiInstance.post("groups", groupData);
	return res;
};

export const getGroupsUserSubscribed = async () => {
	const res = await apiInstance.get("players/self/groups");
	return res;
};

export const getNextGroupEvent = async (groupUniqueIdentifier) => {
	const res = await apiInstance.get(
		`groups/${groupUniqueIdentifier}/fixtures/next`
	);
	return res;
};

export const getGroupTableStandings = async (groupUniqueIdentifier) =>{
	const res = await apiInstance.get(`groups/${groupUniqueIdentifier}/standings`)
	return res;
}