import React from "react";
import { AlertCardDataRequired } from "../components/AlertCardDataRequired";
import { AlertDataRequiredInputs } from "../components/AlertDataRequiredInputs";

function SettingChampionship({
	register,
	valueAllowLeagueBets,
	errors,
	alertRequiredDataDescription,
}) {
	return (
		<div className='collapse' id='collapseSettingChampionship'>
			<h4>Configuración apuestas de campeonato</h4>
			<label htmlFor='date' className='col-1 col-form-label w-auto'>
				Fecha límite para hacer apuestas de campeonato
			</label>
			<div className='col-lg-3 col-sm-6 w-auto'>
				<input
					id='startDate'
					className='form-control'
					type='date'
					{...register("selectLimitedDateToChampionship", {
						required: valueAllowLeagueBets ? true : false,
					})}
				/>
				{errors.selectLimitedDateToChampionship?.type === "required" && (
					<AlertDataRequiredInputs>
						{alertRequiredDataDescription.settingDate}
					</AlertDataRequiredInputs>
				)}
				<span id='startDateSelected'></span>
			</div>

			<div className='mb-3 mt-3'>
				<label htmlFor='nameGroup' className='form-label'>
					Puntos para la apuesta del campeón
				</label>
				<input
					type='number'
					className='form-control'
					id='nameGroup'
					aria-describedby='nameHelp'
					{...register("championBetPoints", {
						required: valueAllowLeagueBets ? true : false,
					})}
				/>
				{errors.championBetPoints?.type === "required" && (
					<AlertDataRequiredInputs>
						{alertRequiredDataDescription.settingPointChampion}
					</AlertDataRequiredInputs>
				)}
			</div>

			<div className='mb-3'>
				<label htmlFor='nameGroup' className='form-label'>
					Puntos para la apuesta del subcampeón
				</label>
				<input
					type='number'
					className='form-control'
					id='nameGroup'
					aria-describedby='nameHelp'
					{...register("runnerUpBetPoints", {
						required: valueAllowLeagueBets ? true : false,
					})}
				/>
				{errors.runnerUpBetPoints?.type === "required" && (
					<AlertDataRequiredInputs>
						{alertRequiredDataDescription.settingPointSubchampion}
					</AlertDataRequiredInputs>
				)}
			</div>
			{errors.runnerUpBetPoints?.type === "required" && (
				<AlertCardDataRequired>
					{alertRequiredDataDescription.createGroupWithSetting}
				</AlertCardDataRequired>
			)}
		</div>
	);
}

export { SettingChampionship };
