import React from 'react';

function InfoCards() {
  return (
    <div className='col-12 col-lg-6'>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className="card card-info">
              <div className="card-body">
                <h5 className="card-title"><strong>Apuesta por ganador</strong></h5>
                <p className="card-text">
                  El apostador elige el equipo ganador o la opción empate.
                </p>
                <ul>
                  <li>
                    Al acertar ganador o empate suma: <strong>+1pt</strong>
                  </li>
                </ul>
              </div>
            </div>

            <div className="card card-info">
              <div className="card-body">
                <h5 className="card-title"><strong>Apuesta por marcador</strong></h5>
                <p className="card-text">
                  El apostador debe pronósticar el marcador final del partido.
                </p>
                <ul>
                  <li>Acertar marcador equipo local: <strong>+1pt</strong></li>
                  <li>Acertar marcador equipo visitante: <strong>+1pt</strong></li>
                  <li>Acertar ganador (o empate): <strong>+1pt</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { InfoCards };