import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { updateFixture } from '../../api/fixtures';

const EditFixture = ({ fixture, onSave }) => {

    const toLocalDateTimeString = (utcDate) => {
        const localDate = new Date(utcDate);
        return new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60000)).toISOString().slice(0, -1);
      };
    
      // Convert local date-time string to UTC date string for saving
      const toUTCDateString = (localDateTime) => {        
        return new Date(localDateTime).toISOString();
      };

    const [currentFixture, setCurrentFixture] = useState({
        ...fixture,
        date: toLocalDateTimeString(fixture.date),
    });
    
    const timeZoneOffset = (new Date().getTimezoneOffset() * -1) / 60;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentFixture({
        ...currentFixture,
        [name]: value
        });
    };

    const handleTeamChange = (e, teamType) => {
        const { name, value } = e.target;
        setCurrentFixture({
        ...currentFixture,
        [teamType]: {
            ...currentFixture[teamType],
            [name]: value
        }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataToSave = {
            ...currentFixture,
            date: toUTCDateString(currentFixture.date),
            fullTimeAwayGoals: currentFixture.fullTimeAwayGoals ? Number(currentFixture.fullTimeAwayGoals) : null,
            fullTimeHomeGoals: currentFixture.fullTimeHomeGoals ? Number(currentFixture.fullTimeHomeGoals) : null
        };

        const result = await updateFixture(dataToSave);

        if(!result?.data?.Result){            
            alert('Error actualizando el fixture.');
            return;
        }

        if(onSave){
            onSave(dataToSave);
        }
    };

  

    return (
        <div className="container mt-5">
        <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
            <label htmlFor="leagueName">League Name</label>
            <input
            readOnly
            disabled
            type="text"
            className="form-control"
            id="leagueName"
            name="name"
            value={currentFixture.league.name}
            onChange={(e) => handleTeamChange(e, 'league')}
            />
        </div>
        <div className="row">
            <div className="col-md-6">
            <div className="form-group mb-3">
                <label htmlFor="round">Stage</label>
                <input
                type="text"
                className="form-control"
                id="round"
                name="round"
                value={currentFixture.round}
                onChange={handleChange}
                />
            </div>
            </div>
            <div className="col-md-6">
            <div className="form-group mb-3">
                <label htmlFor="date">Date (GMT {timeZoneOffset})</label>
                <input
                type="datetime-local"
                className="form-control"
                id="date"
                name="date"
                value={currentFixture.date}
                onChange={handleChange}
                />
            </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
            <div className="form-group mb-3">
                <label htmlFor="venueName">Venue Name</label>
                <input
                type="text"
                className="form-control"
                id="venueName"
                name="name"
                value={currentFixture.venue.name}
                onChange={(e) => handleTeamChange(e, 'venue')}
                />
            </div>
            </div>
            <div className="col-md-6">
            <div className="form-group mb-3">
                <label htmlFor="venueCity">Venue City</label>
                <input
                type="text"
                className="form-control"
                id="venueCity"
                name="city"
                value={currentFixture.venue.city}
                onChange={(e) => handleTeamChange(e, 'venue')}
                />
            </div>
            </div>
        </div>
        
        <div className="row">
            <div className="col-md-6">
            <div className="form-group mb-3">
                <label htmlFor="homeTeam">Home Team</label>
                <input
                readOnly
                disabled
                type="text"
                className="form-control"
                id="homeTeam"
                name="name"
                value={currentFixture.homeTeam.name}
                onChange={(e) => handleTeamChange(e, 'homeTeam')}
                />
            </div>
            </div>
            <div className="col-md-6">
            <div className="form-group mb-3">
                <label htmlFor="awayTeam">Away Team</label>
                <input
                readOnly
                disabled
                type="text"
                className="form-control"
                id="awayTeam"
                name="name"
                value={currentFixture.awayTeam.name}
                onChange={(e) => handleTeamChange(e, 'awayTeam')}
                />
            </div>
            </div>
        </div>

        <div className="row">
            <div className="col-md-6">
            <div className="form-group mb-3">
                <label htmlFor="homeTeam">Home Team Score</label>
                <input              
                type="number"
                className="form-control"
                id="fullTimeHomeGoals"
                name="fullTimeHomeGoals"
                value={currentFixture.fullTimeHomeGoals === undefined ? '' : currentFixture.fullTimeHomeGoals}
                onChange={(e) => handleChange(e)}
                />
            </div>
            </div>
            <div className="col-md-6">
            <div className="form-group mb-3">
                <label htmlFor="awayTeam">Away Team Score</label>
                <input
                type="text"
                className="form-control"
                id="fullTimeAwayGoals"
                name="fullTimeAwayGoals"
                value={currentFixture.fullTimeAwayGoals === undefined ? '' : currentFixture.fullTimeAwayGoals}
                onChange={(e) => handleChange(e)}
                />
            </div>
            </div>
        </div>      
        <div className="form-group text-center">
            <button type="submit" className="btn btn-primary mt-3">Save</button>
        </div>
        </form>
    </div>
    );  
};

export default EditFixture;
