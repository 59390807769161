import React, { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AlertDataRequiredInputs } from "../components/AlertDataRequiredInputs";
import { AlertCardDataRequired } from "../components/AlertCardDataRequired";
import { AlertCardSucessProcess } from "../components/AlertCardSucessProcess";
import { useUser } from "../context/userContext";

function FormRecoverPassword() {
	const {
		recoverPassword,
		loadingRecoverPassword,
		changePassword,
		loadingChangePassword,
	} = useUser();

	const [searchParams, setSearchParams] = useSearchParams();
	let recoveryToken = searchParams.get("token");

	const errorMessages = {
		emailRequired: "Falta rellenar el email",
		emailInvalidFormat: "El formato del email es incorrecto",
		passwordRequired: "Debe ingresar una contraseña",
		passwordInvalidLength: "La contraseña debe tener al menos 8 caracteres",
		passwordConfirmationMismatch: "Las contraseñas no coinciden",
	};

	const {
		register,
		formState: { errors },
		watch,
		handleSubmit,
	} = useForm();
	const validationSamePassword = watch("password_2") === watch("newpassword");

	const [apiResponse, setApiResponse] = useState(null);
	const onSubmit = (data) => {
		const catchRequest = recoverPassword(data);
		catchRequest.then((res) => {
			setApiResponse(res);
		});
	};

	const onTokenFormSubmit = (data) => {
		data.passwordrecoverytoken = recoveryToken;
		const catchRequest = changePassword(data);
		catchRequest.then((res) => {
			setApiResponse(res);
		});
	};

	return recoveryToken ? (
		<form onSubmit={handleSubmit(onTokenFormSubmit)}>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-12 col-md-5'>
						<div className='card card-form_register_user card-body mb-0'>
							<div className='container-img_register d-flex justify-content-center'>
								<h2>Cambiar contraseña</h2>
							</div>

							{/* Verification Password */}
							{/* Verification Password-1 */}
							<div className='mb-3'>
								<label htmlFor='newpassword' className='form-label'>
									Nueva constraseña
								</label>
								<input
									name='newpassword'
									type='password'
									className='form-control'
									id='newpassword'
									{...register("newpassword", {
										required: true,
										minLength: 8,
									})}
								/>
								{errors.newpassword?.type === "required" && (
									<AlertDataRequiredInputs>
										{errorMessages.passwordRequired}
									</AlertDataRequiredInputs>
								)}
								{errors.newpassword?.type === "minLength" && (
									<AlertDataRequiredInputs>
										{errorMessages.passwordInvalidLength}
									</AlertDataRequiredInputs>
								)}
							</div>
							{/* Verification Password-1 */}

							{/* Verification Password-2 Repeat */}
							{
								<div className='mb-3'>
									<label htmlFor='password-confirmation' className='form-label'>
										Escribe nuevamente la constraseña
									</label>
									<input
										name='password-confirmation'
										type='password'
										className='form-control'
										id='password-confirmation'
										{...register("password_2", {
											required: true,
										})}
									/>
									{errors.password_2?.type === "required" && (
										<AlertDataRequiredInputs>
											{errorMessages.passwordRequired}
										</AlertDataRequiredInputs>
									)}
									{!validationSamePassword && (
										<AlertDataRequiredInputs>
											{errorMessages.passwordConfirmationMismatch}
										</AlertDataRequiredInputs>
									)}
								</div>
							}
							{/* Verification Password-2 Repeat */}

							{/* Verification Password */}
							<Fragment>
								<button
									className='btn btn-primary btn-create_account'
									type='submit'
									disabled={loadingChangePassword}
								>
									{loadingChangePassword && (
										<span
											className='spinner-border spinner-border-sm'
											role='status'
											aria-hidden='true'
										></span>
									)}
									{loadingChangePassword ? "Enviando..." : "Enviar"}
								</button>
							</Fragment>

							{apiResponse &&
								(apiResponse?.IsSuccess ? (
									<AlertCardSucessProcess>
										Se ha actualizado la contraseña correctamente
									</AlertCardSucessProcess>
								) : (
									<AlertCardDataRequired>
										{apiResponse?.Errors[0]}
									</AlertCardDataRequired>
								))}
						</div>
					</div>
				</div>
			</div>
		</form>
	) : (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-12 col-md-5'>
						<div className='card card-form_register_user card-body mb-0'>
							<div className='container-img_register d-flex justify-content-center'>
								<h2>Recuperar contraseña</h2>
							</div>
							{/* Verification Email */}
							<div className='mb-3'>
								<label htmlFor='email' className='form-label'>
									Correo electrónico
								</label>
								<input
									type='email'
									className='form-control'
									id='email'
									aria-describedby='emailHelp'
									{...register("email", {
										required: true,
										pattern: /\S+@\S+\.\S+/,
									})}
								/>
								{errors.email?.type === "required" && (
									<AlertDataRequiredInputs>
										{errorMessages.emailRequired}
									</AlertDataRequiredInputs>
								)}
								{errors.email?.type === "pattern" && (
									<AlertDataRequiredInputs>
										{errorMessages.emailInvalidFormat}
									</AlertDataRequiredInputs>
								)}
							</div>
							{/* Verification Email */}
							<Fragment>
								<button
									className='btn btn-primary btn-create_account'
									type='submit'
									disabled={loadingRecoverPassword}
								>
									{loadingRecoverPassword && (
										<span
											className='spinner-border spinner-border-sm'
											role='status'
											aria-hidden='true'
										></span>
									)}
									{loadingRecoverPassword ? "Recuperando..." : "Recuperar"}
								</button>
							</Fragment>

							{apiResponse &&
								(apiResponse?.IsSuccess ? (
									<AlertCardSucessProcess>
										Hemos enviado un correo con las instrucciones para recuperar
										tu contraseña.
									</AlertCardSucessProcess>
								) : (
									<AlertCardDataRequired>
										{apiResponse?.Errors[0]}
									</AlertCardDataRequired>
								))}
						</div>
					</div>
				</div>
			</div>
		</form>
	);
}

export { FormRecoverPassword };
