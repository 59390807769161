import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { CardInfoTableGroupSelected } from "../components/CardInfoTableGroupSelected";
import { InfoCardNextEventGroupSelected } from "../components/InfoCardNextEventGroupSelected";
import { FixtureBets } from "../pages/FixtureBets";
import { SelfFixtureLeagueBets } from "./SelfFixtureLeagueBets";
import { ToastContainer } from "react-toastify";
import { useGroup } from "../context/groupContext";
import { useBets } from "../context/betsContext";
import { useAppContext } from "../context";
import GroupLeagueBets from "./LeagueBets/GroupLeagueBets";

function InfoGroupSelected({
	currentUniqueIdentifierSelected,
	nameCurrentGroupSelect,
	isGroupAdmin,
	accessCode,
}) {
	const { leaveGroup, loadingLeaveGroup } = useGroup();
	const {
		loadingUpdateLegueBets,
		setLoadingUpdateLegueBets,
		getInfoLeagueTeamSelectedForLeagueBets,
		infoLeagueTeamSelected,
	} = useBets();

	const { toastMessage } = useAppContext();

	const [messageErrorToleaveGroup, setMessageErrorToleaveGroup] = useState(
		"Ha ocurrido un error"
	);
	const [widthScreen, setWidthScreen] = useState(false);

	useEffect(() => {
		getInfoLeagueTeamSelectedForLeagueBets(currentUniqueIdentifierSelected);
		setLoadingUpdateLegueBets(false);
	}, [currentUniqueIdentifierSelected]);

	const handleClickToLeaveGroup = async () => {
		if (
			!window.confirm(
				"Seguro desea abandonar el grupo " + nameCurrentGroupSelect
			)
		)
			return;

		const apiResponse = await leaveGroup(currentUniqueIdentifierSelected);
		if (!apiResponse.IsSuccess) {
			setMessageErrorToleaveGroup(apiResponse.Errors[0]);
			toastMessage(messageErrorToleaveGroup, "error");
		}
	};

	// let widthLarge = false;
	window.addEventListener("resize", checkingWidthScreen);
	useEffect(() => {
		if (window.innerWidth > 992) setWidthScreen(true);
	}, []);
	function checkingWidthScreen() {
		if (window.innerWidth > 992) {
			setWidthScreen(true);
		} else {
			setWidthScreen(false);
		}
	}

	const leagueBetsLargeScreen = () => (
		<div className='card mb-5'>
			<div className='card-header card-header-color text-center'>
				<strong>Pronósticos de campeonato</strong>
			</div>
			<div className='card-body'>
				<div className='card-title text-center mb-3'>
					{loadingUpdateLegueBets ? (
						<div className='spinner-border text-dark' role='status'>
							<span className='visually-hidden'>Loading...</span>
						</div>
					) : (
						// <SelfFixtureLeagueBets
						// 	currentUniqueIdentifierSelected={currentUniqueIdentifierSelected}
						// />
						<GroupLeagueBets currentUniqueIdentifierSelected={currentUniqueIdentifierSelected}></GroupLeagueBets>
					)}
				</div>
			</div>
		</div>
	);

	const betsLargeScreen = () => (
		<div className='card'>
			<div className='card-header card-header-color text-center'>
				<strong>Partidos por pronosticar</strong>
			</div>

			<div className='card-body'>
				<div className='card-title text-center mb-3'>
					<FixtureBets />
				</div>
			</div>
		</div>
	);

	const cardTableInfoLargeScreen = () => (
		<>
			<div className='card'>
				<div className='card-header card-header-color text-center'>
					<strong>Tabla de posiciones</strong>
				</div>
				<div className='card-body overflow-auto'>
					{isGroupAdmin && (
						<div className='row'>
							<div className='col'>
								Invita jugadores a unirse al grupo con el código{" "}
								<strong>{accessCode}</strong>
							</div>
						</div>
					)}
					<CardInfoTableGroupSelected
						currentUniqueIdentifierSelected={currentUniqueIdentifierSelected}
					/>
				</div>
			</div>
		</>
	);

	const cardNextEventInfoLargeScreen = () => (
		<div className='card text-center mt-4'>
			<div className='card-header card-header-color'>
				<strong>Próximo Evento</strong>
			</div>
			<div className='card-body'>
				<InfoCardNextEventGroupSelected
					currentUniqueIdentifierSelected={currentUniqueIdentifierSelected}
				/>
			</div>
		</div>
	);

	const betsSmallScreen = () => (
		<div className='accordion-item'>
			<h2 className='accordion-header' id='headingThreeBets'>
				<button
					className='accordion-button collapsed'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#collapseThreeBets'
					aria-expanded='true'
					aria-controls='collapseThreeBets'
				>
					Partidos por pronosticar
				</button>
			</h2>
			<div
				id='collapseThreeBets'
				className='accordion-collapse collapse'
				aria-labelledby='headingThreeBets'
				data-bs-parent='#accordionExample'
			>
				<div className='accordion-body'>
					{infoLeagueTeamSelected.Result?.length > 0 && (						
						<>
							<h2 className='text-center mt-5'>Pronósticos de campeonato</h2>
							<div className='text-center mb-3'>
								{loadingUpdateLegueBets ? (
									<div className='spinner-border text-dark' role='status'>
										<span className='visually-hidden'>Loading...</span>
									</div>
								) : (									
									<GroupLeagueBets currentUniqueIdentifierSelected={currentUniqueIdentifierSelected}></GroupLeagueBets>
								)}
							</div>
						</>
					)}
					<FixtureBets />
				</div>
			</div>
		</div>
	);

	const cardTableInfoSmallScreen = () => (
		<div className='accordion-item '>
			<h2 className='accordion-header text-center' id='headingTwoTable'>
				<button
					className='accordion-button collapsed'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#collapseTwoTable'
					aria-expanded='true'
					aria-controls='collapseTwoTable'
				>
					Tabla de posiciones
				</button>
			</h2>
			<div
				id='collapseTwoTable'
				className='accordion-collapse collapse'
				aria-labelledby='headingTwoTable'
				data-bs-parent='#accordionExample'
			>
				<div className='accordion-body'>
					{isGroupAdmin && (
						<div className='row'>
							<div className='col'>
								Invita jugadores a unirse al grupo con el código{" "}
								<strong>{accessCode}</strong>
							</div>
						</div>
					)}
					<CardInfoTableGroupSelected
						currentUniqueIdentifierSelected={currentUniqueIdentifierSelected}
					/>
				</div>
			</div>
		</div>
	);

	const cardNextEventInfoSmallScreen = () => (
		<div className='accordion-item'>
			<h2 className='accordion-header' id='headingOneNextEvent'>
				<button
					className='accordion-button collapsed'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#collapseTwoNextEvent'
					aria-expanded='true'
					aria-controls='collapseTwoNextEvent'
				>
					Próximo Evento
				</button>
			</h2>
			<div
				id='collapseTwoNextEvent'
				className='accordion-collapse collapse'
				aria-labelledby='headingOneNextEvent'
				data-bs-parent='#accordionExample'
			>
				<div className='accordion-body'>
					<div className='card text-center mt-4'>
						<div className='card-body'>
							<InfoCardNextEventGroupSelected
								currentUniqueIdentifierSelected={
									currentUniqueIdentifierSelected
								}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
	return (
		<div className='container-fluid'>
			{messageErrorToleaveGroup && <ToastContainer limit={4} />}
			<div className='row'>
				<div className='col'>
					<div className='card mt-2'>
						<div className='card-body'>
							<h5 className='card-title text-center mb-3'>
								{nameCurrentGroupSelect}
							</h5>

							<div className='row justify-content-center'>
								{isGroupAdmin && (
									<div className='col-12 col-lg-2'>
										<Link to={`/edit-group/${currentUniqueIdentifierSelected}`}>
											<button
												type='button'
												className='btn btn-sm btn-primary mx-2 mb-4 w-100 buttons-page-groups'
											>
												Editar grupo
											</button>
										</Link>
									</div>
								)}

								<div className='col-12 col-lg-2'>
									<Link to='/groups/join'>
										<button
											type='button'
											className='btn btn-sm btn-primary mx-2 mb-4 w-100 buttons-page-groups'
										>
											Unirme a un nuevo grupo
										</button>
									</Link>
								</div>

								<div className='col-12 col-lg-2'>
									<button
										type='button'
										className='btn  btn-sm btn-primary mx-2 mb-4 w-100 buttons-page-groups'
										onClick={handleClickToLeaveGroup}
									>
										Abandonar grupo
									</button>
								</div>

								<div className='col-12 col-lg-2'>
									<NavLink to='/create-new-group'>
										<button
											type='button'
											className='btn btn-sm btn-primary mx-2 mb-4 w-100 buttons-page-groups'
										>
											Crear un grupo
										</button>
									</NavLink>
								</div>
							</div>
							{currentUniqueIdentifierSelected[0] != null ? (
								<div className='container-fluid px-0'>
									<div className='row'>
										{widthScreen ? (
											<>
												<div className='col-12 col-lg-6'>
													{betsLargeScreen()}
												</div>
												<div className='col-12 col-lg-6'>
													{infoLeagueTeamSelected.Result?.length > 0 &&
														leagueBetsLargeScreen()}
													{cardTableInfoLargeScreen()}
													{/* {cardNextEventInfoLargeScreen()} */}
												</div>
											</>
										) : (
											<>
												<div className='accordion' id='accordionExample'>
													<div className='col-12 col-lg-6'>
														{betsSmallScreen()}
													</div>
													<div className='col-12 col-lg-6'>
														{cardTableInfoSmallScreen()}
													</div>
													<div className='col-12 col-lg-6 my-2'>
														{/* {cardNextEventInfoSmallScreen()} */}
													</div>

													{/* <div className='col-12 col-lg-6 my-2'>
														{infoSelectedLeagueBet.Result?.length > 0 &&
															leagueBetsSmallScreen()}
													</div> */}
												</div>
											</>
										)}
									</div>
								</div>
							) : (
								<h2 className='text-center my-4'>
									Crea tu primero grupo o unete a uno para comenzar
								</h2>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export { InfoGroupSelected };
