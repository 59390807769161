import { apiInstance } from "../apiInstance";

export const postRegisterUser = async (dataUser) => {
	const res = await apiInstance.post("auth/register", dataUser);
	return res;
};

export const postLoginUser = async (dataUser) => {
	const res = await apiInstance.post("auth/login", dataUser);
	return res;
};

export const postRecoverPassword = async (dataUser) => {
	const res = await apiInstance.post("auth/recoverpassword", dataUser);
	return res;
};

export const postChangePassword = async (dataUser) => {
	const res = await apiInstance.post("auth/changeuserpassword", dataUser);
	return res;
};
