import React from 'react';
import FixtureListItem from './FixtureListItem';

const FixtureList = ({fixtures}) =>{
    if(!fixtures){
        return (<></>);
    }

    return (
        <div className="container mt-5">
          <table className="table table-bordered table-responsive">
            <thead className="thead-dark">
              <tr>
                <th>Date</th>
                <th>League</th>
                <th>Stage</th>
                <th>Status</th>
                <th>Home Team</th>
                <th></th>
                <th>Away Team</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {fixtures.map((fixture) => (
                <FixtureListItem key={fixture.uniqueIdentifier} fixture={fixture}></FixtureListItem>
          ))}
        </tbody>
      </table>
    </div>
    );


};

export default FixtureList;