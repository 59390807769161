
import { apiInstance } from "../apiInstance";

export const getLeagues = async (filters) => {
	let url = "leagues";

	if(filters?.activesOnly){
		url += '?activesOnly=true';
	}
	const res = await apiInstance.get(url);
	return res;
};

export const getLeagueTeams = async (leagueIdentifier) => {
	const res = await apiInstance.get(`leagues/${leagueIdentifier}/teams`);
	return res;
};