import React, { useEffect } from "react";
import { InfoGroupSelected } from "./InfoGroupSelected";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGroup } from "../context/groupContext";
import { useBets } from "../context/betsContext";
import { useAppContext } from "../context";

function SelectGroup() {
	const {
		getInfoGroupsSubscribed,
		infoGroupsUserSubscribed,
		loadingGroupsSubscribed,
	} = useGroup();

	const { setLoadingUpdateLegueBets } = useBets();

	const {
		currentUniqueIdentifierSelected,
		setCurrentUniqueIdentifierSelected,
	} = useAppContext();

	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	let groupId = searchParams.get("g");

	useEffect(() => {
		getInfoGroupsSubscribed();
	}, []);

	let nameCurrentGroupSelect = ""; // will use inner Select
	let initialValueForSelect = ""; // First Render for Select
	let accessCode = "";
	let isGroupAdmin = false;

	if (
		!loadingGroupsSubscribed &&
		infoGroupsUserSubscribed.Result.length !== 0
	) {
		initialValueForSelect =
			infoGroupsUserSubscribed.Result[0]?.uniqueIdentifier;
	} else {
		initialValueForSelect = [null];
	}

	useEffect(() => {
		setCurrentUniqueIdentifierSelected(initialValueForSelect);
	}, [infoGroupsUserSubscribed]);

	const handleOnchangeToSelectedGroup = (event) => {
		setLoadingUpdateLegueBets(true);
		setCurrentUniqueIdentifierSelected(event.target.value);
		navigate(`/groups?g=${event.target.value}`);
	};

	useEffect(() => {
		if (groupId) {
			setCurrentUniqueIdentifierSelected(groupId);
		}
	});

	return (
		<React.Fragment>
			<div className='container-fluid mt-4'>
				<div className='row'>
					<div className='col'>
						<div className='card'>
							<div className='card-body d-flex justify-content-between align-items-center'>
								<label htmlFor='select-group' className='form-label w-25'>
									<strong>Grupo:</strong>
								</label>
								{loadingGroupsSubscribed ? (
									<div className='spinner-border text-dark' role='status'>
										<span className='visually-hidden'>Cargando...</span>
									</div>
								) : (
									<div className='input-group'>
										<select
											className='form-select input-select-group-fixture-bets'
											id='select-group'
											aria-label='Default select example'
											onChange={handleOnchangeToSelectedGroup}
											defaultValue={groupId}
										>
											{infoGroupsUserSubscribed.Result?.map((item) => {
												if (
													currentUniqueIdentifierSelected ===
													item.uniqueIdentifier
												) {
													nameCurrentGroupSelect = item.name; // Getting the name Group Current Selected
													accessCode = item.accessCode;
													isGroupAdmin = item.isGroupAdmin;
												}
												return (
													<option
														name={item.name}
														key={item.uniqueIdentifier}
														value={item.uniqueIdentifier} //send uniqueIdentifier for use in fetch
													>
														{item.name}
													</option>
												);
											})}
										</select>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{currentUniqueIdentifierSelected && (
				<InfoGroupSelected
					currentUniqueIdentifierSelected={currentUniqueIdentifierSelected}
					nameCurrentGroupSelect={nameCurrentGroupSelect}
					isGroupAdmin={isGroupAdmin}
					accessCode={accessCode}
				/>
			)}
		</React.Fragment>
	);
}

export { SelectGroup };
