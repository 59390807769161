import React, { useEffect, useState,useContext } from "react";
import { apiInstance } from "../apiInstance";
import { toast } from "react-toastify";
import { useUser } from "../context/userContext";
import { jwtDecode } from "jwt-decode";

const AppContext = React.createContext();

export const useAppContext = () => {
	const context = useContext(AppContext);
	return context;
};

function AppProvider(props) {
	const { userInfoLogin } = useUser();

	const [modalLogin, setModalLogin] = useState(false);
	const handleClickModalLogin = () => setModalLogin(!modalLogin);

	const [modalLoadingRequest, setModalLoadingRequest] = useState(false);
	const handleClickModalLoadingRequest = () =>
		setModalLoadingRequest(!modalLoadingRequest);
	const [loadinguserInfoLogin, setLoadinguserInfoLogin] = useState(false);

	const [catchingError401ExpiredToken, setCatchingError401ExpiredToken] =
		useState("");

	const [currentUniqueIdentifierSelected, setCurrentUniqueIdentifierSelected] =
		useState("");

	const [messageError, setMessageError] = useState(null);

	apiInstance.interceptors.response.use(
		(response) => {
			if (!response.data.IsSuccess) {
				setMessageError(response.data.Errors[0]);
				return response;
			}
			setMessageError(null);
			return response;
		},
		(error) => {
			console.log(error.response);
			let messageError = "";
			const isSucess = error.response.data.IsSuccess;
			if (!isSucess && error.response.data.Errors != null) {
				messageError = error.response.data.Errors[0];
			}

			if (!messageError) {
				messageError = error.response.data.StatusCode;
			}

			setMessageError("Lo sentimos hubo un error " + messageError);

			if (error.response.status === 401) {
				setCatchingError401ExpiredToken("no token");
			}
			return error.response;
			//return Promise.reject();
		}
	);

	useEffect(() => {
		//Effect for LocalStorage with Login
		if (userInfoLogin) {
			savingLocalStorage();
		}
	}, [userInfoLogin]);

	const checkingLocalStorage = localStorage.getItem("userApuestaco");

	const savingLocalStorage = () => {
		setLoadinguserInfoLogin(true);

		if (checkingLocalStorage === null) {

			if(userInfoLogin && userInfoLogin?.token){
				var tokenInfo = jwtDecode(userInfoLogin.token);
				userInfoLogin.role = tokenInfo.role;
			}

			localStorage.setItem("userApuestaco", JSON.stringify(userInfoLogin));
			setLoadinguserInfoLogin(false);
		}
	};

	// TOAST
	const toastMessage = (message, typeToast) => {
		if (typeToast === "error") {
			toast.error(message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}

		if (typeToast === "info") {
			toast.info(message, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
	};

	return (
		<AppContext.Provider
			value={{
				setMessageError,
				messageError,
				toastMessage,

				handleClickModalLogin,
				modalLogin,
				handleClickModalLoadingRequest,
				loadinguserInfoLogin,

				savingLocalStorage,

				catchingError401ExpiredToken,
				setCatchingError401ExpiredToken,
				setModalLogin,

				currentUniqueIdentifierSelected,
				setCurrentUniqueIdentifierSelected,
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
}

export { AppProvider, AppContext };
