import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context";

const AlertUserSessionExpiredToken = () => {
	const {
		catchingError401ExpiredToken,
		setCatchingError401ExpiredToken,
		setGetInfoCurrentBetsUser,
		setUserInfoLogin,
		setModalLogin,
	} = useAppContext();

	const navigate = useNavigate();

	function resetTokenUser() {
		navigate("/");
		setModalLogin(true);
		localStorage.removeItem("userApuestaco");
		setUserInfoLogin(null);
		setGetInfoCurrentBetsUser({});
		setCatchingError401ExpiredToken("");
	}	

	if (catchingError401ExpiredToken === "no token") {
		window.scroll({
			top: 0,
		});
		setTimeout(() => {
			resetTokenUser();
		}, 5000);
	}
	return (
		<>
			{catchingError401ExpiredToken.length > 0 && (
				<div className='alert alert-danger text-center m-3' role='alert'>
					Tu sesión ha expirado y los cambios no han sido guardado, vuelve a
					iniciar sesión nuevamente
				</div>
			)}
		</>
	);
};

export { AlertUserSessionExpiredToken };
