import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useBets } from "../context/betsContext";
import { useAppContext } from "../context";

function SelfFixtureBetsScoreAndWinner({ infoPlayerBetsWithDateChanged }) {
	const { updateFixtureBet } = useBets();

	const { currentUniqueIdentifierSelected } = useAppContext();

	const [userBets, setUserBet] = useState(infoPlayerBetsWithDateChanged);
	let values = [...userBets];

	useEffect(() => {
		setUserBet(infoPlayerBetsWithDateChanged);
	}, [userBets, infoPlayerBetsWithDateChanged]);

	const handleCheckRadiusOnchange = async (
		index,
		event,
		groupIdentifier,
		fixtureIdentifier
	) => {		
		if (event.target.id === "check-input-radio-bet-fixtures-1") {
			values[index].bet.playerBet = 1;
		}
		if (event.target.id === "check-input-radio-bet-fixtures-2") {
			values[index].bet.playerBet = 2;
		}
		if (event.target.id === "check-input-radio-bet-fixtures-3") {
			values[index].bet.playerBet = 3;
		}
		setUserBet(values);

		const completeInfoToUpdateBets = {
			fixtureIdentifier: fixtureIdentifier,
			groupIdentifier: groupIdentifier,
			winnerBet: values[index].bet.playerBet,
			homeTeamScore: null,
			awayTeamScore: null,
		};
		const data = await updateFixtureBet(completeInfoToUpdateBets);
	};

	const handleInputOnchange = async (
		index,
		event,
		groupIdentifier,
		fixtureIdentifier,
		winnerBet = null
	) => {
		if (event.target.name === "homeTeamScore") {
			values[index].bet.homeTeamScore = event.target.value =
				event.target.value.slice(0, 2); //Limited the length into input
		}
		if (event.target.name === "awayTeamScore") {
			values[index].bet.awayTeamScore = event.target.value =
				event.target.value.slice(0, 2); //Limited the length into input
		}
		setUserBet(values);

		const completeInfoToUpdateBets = {
			fixtureIdentifier: fixtureIdentifier,
			groupIdentifier: groupIdentifier,
			winnerBet: null,
			homeTeamScore: Number(values[index].bet.homeTeamScore) || 0,
			awayTeamScore: Number(values[index].bet.awayTeamScore) || 0,
		};
		const data = await updateFixtureBet(completeInfoToUpdateBets); //answer from API
	};

	return (
		<React.Fragment>
			{infoPlayerBetsWithDateChanged &&
				userBets?.map((info, index) => {
					if (
						info.group.fixtureBetTypeId === 2 &&
						info.group.uniqueIdentifier === currentUniqueIdentifierSelected
					) {
						return (
							<form key={`score uniqueIdentifier ${uuidv4()}`}>
								<div className='card-body px-0 my-4 d-flex flex-column align-items-center'>
									<div className='card pt-0 mx-5 card-container-info-selftFixtureBets'>
										<div className='card-body px-0'>
											<div className='container'>
												<div className='row'>
													<div className='col-12 mb-2 text-center container-date-matchs pb-2'>
														{info.fixture.date}
													</div>

													<div className='col-6 d-flex flex-column align-items-center'>
														<div className='col'>
															<img
																className='img-logo_team_fixture_bets'
																src={info.fixture.homeTeam.logoUrl}
																alt='logo home team'
															/>
														</div>
													</div>

													<div className='col-6 d-flex flex-column align-items-center'>
														<div className='col'>
															<img
																className='img-logo_team_fixture_bets'
																src={info.fixture.awayTeam.logoUrl}
																alt='logo away team'
															/>
														</div>
													</div>
												</div>

												<div className='row align-items-center'>
													<div className='col-6 col-md-6 text-center'>
														<div className='container-input-number-fixture-bets d-flex flex-column align-items-center'>
															<label
																className='form-check-label label-input-fixture-bets-score my-2'
																htmlFor={`check-input-fixtures-bets-1-${info.fixture.uniqueIdentifier}`}
															>
																{info.fixture.homeTeam.name}
															</label>
															<input
																className='form-control input-number-fixture-bets'
																type='number'
																min={0}
																max={99}
																name='homeTeamScore'
																id={`input-fixtures-bets-${index}`}
																onChange={(event) =>
																	handleInputOnchange(
																		index,
																		event,
																		info.group.uniqueIdentifier,
																		info.fixture.uniqueIdentifier,
																		null
																	)
																}
																// placeholder={info.bet?.homeTeamScore}
																value={info.bet.homeTeamScore}
															/>
														</div>
													</div>

													<div className='container-input-number-fixture-bets col-6 col-md-6 text-center'>
														<div className=' d-flex flex-column align-items-center'>
															<label
																className='form-check-label label-input-fixture-bets-score my-2'
																htmlFor={`check-input-fixtures-bets-2-${info.fixture.uniqueIdentifier}`}
															>
																{info.fixture.awayTeam.name}
															</label>
															<input
																className='form-control input-number-fixture-bets'
																type='number'
																min={0}
																max={99}
																name='awayTeamScore'
																id={`input-fixtures-bets-${index}`}
																onChange={(event) =>
																	handleInputOnchange(
																		index,
																		event,
																		info.group.uniqueIdentifier,
																		info.fixture.uniqueIdentifier,
																		null
																	)
																}
																// placeholder={info.bet?.awayTeamScore}
																value={info.bet.awayTeamScore}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						);
					}
					// </ Card Self Fixture Bets Type Score>

					// <Card Self Fixture Bets Type Winner>
					if (
						info.group.fixtureBetTypeId === 1 &&
						info.group.uniqueIdentifier === currentUniqueIdentifierSelected
					) {
						return (
							<form key={`winner uniqueIdentifier ${uuidv4()}`}>
								<div className='card-body px-0 my-4 d-flex flex-column align-items-center'>
									<div className='card pt-0 mx-5 card-container-info-selftFixtureBets'>
										{/* <h2 className='mt-1 mx-2 text-center title-name-group-fixture-bets pt-2'>
											<strong>{info.group.name}</strong>
										</h2> */}

										<div className='card-body px-0'>
											<div className='container'>
												<div className='row'>
													<div className='col-12 mb-3 text-center container-date-matchs'>
														{info.fixture.date}
													</div>

													<div className='col-6 d-flex flex-column align-items-center'>
														<div className='col'>
															<img
																className='img-logo_team_fixture_bets'
																src={info.fixture.homeTeam.logoUrl}
																alt='logo'
															/>
														</div>
													</div>

													<div className='col-6 d-flex flex-column align-items-center'>
														<div className='col'>
															<img
																className='img-logo_team_fixture_bets'
																src={info.fixture.awayTeam.logoUrl}
																alt='logo'
															/>
														</div>
													</div>
												</div>
												{/* ---//---*/}
												<div className='row row-grid-checks-input'>
													<div className='col grid-colum-input-check-1 container-form-check col-md-3 text-center'>
														<div className='form-check d-flex flex-column align-items-center p-0'>
															<label
																className='form-check-label label-check-fixture-bets-options my-2'
																htmlFor='check-input-radio-bet-fixtures-1'
															>
																{info.fixture.homeTeam.name}
															</label>

															<input
																checked={
																	info.bet.playerBet === 1 ? true : false
																}
																className='form-check-input input-check-fixture-bets'
																type='radio'
																name='playerBet'
																id='check-input-radio-bet-fixtures-1'
																onChange={(event) =>
																	handleCheckRadiusOnchange(
																		index,
																		event,
																		info.group.uniqueIdentifier,
																		info.fixture.uniqueIdentifier
																	)
																}
																value={info.bet.playerBet}
															/>
														</div>
													</div>

													<div className='col-12 grid-colum-input-check-2 container-form-check col-md-3 text-center'>
														<div className='form-check d-flex flex-column align-items-center p-0'>
															<label
																className='form-check-label label-check-fixture-bets-options draw-score-input-check my-2'
																htmlFor='check-input-radio-bet-fixtures-3'
															>
																Empate
															</label>
															<input
																checked={
																	info.bet.playerBet === 3 ? true : false
																}
																className='form-check-input input-check-fixture-bets'
																type='radio'
																name='playerBet'
																id='check-input-radio-bet-fixtures-3'
																onChange={(event) =>
																	handleCheckRadiusOnchange(
																		index,
																		event,
																		info.group.uniqueIdentifier,
																		info.fixture.uniqueIdentifier
																	)
																}
																value={info.bet.playerBet}
															/>
														</div>
													</div>

													<div className='col grid-colum-input-check-3 container-form-check col-md-3 text-center'>
														<div className='form-check d-flex flex-column align-items-center p-0'>
															<label
																className='form-check-label label-check-fixture-bets-options my-2'
																htmlFor='check-input-radio-bet-fixtures-2'
															>
																{info.fixture.awayTeam.name}
															</label>
															<input
																checked={
																	info.bet.playerBet === 2 ? true : false
																}
																className='form-check-input input-check-fixture-bets'
																type='radio'
																name='playerBet'
																id='check-input-radio-bet-fixtures-2'
																onChange={(event) =>
																	handleCheckRadiusOnchange(
																		index,
																		event,
																		info.group.uniqueIdentifier,
																		info.fixture.uniqueIdentifier
																	)
																}
																value={info.bet.playerBet}
															/>
														</div>
													</div>
												</div>
												{/* ---//--- */}
											</div>
										</div>
									</div>
								</div>
							</form>
						);
					}
					// </Card Self Fixture Bets Type Winner>
				})}
		</React.Fragment>
	);
}

export { SelfFixtureBetsScoreAndWinner };
