import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import WOW from 'wowjs';

function HomeInfoAboutApuestaco() {

  const navigate = useNavigate();
  

  useEffect(() => {
    const checkingLocalStorage = localStorage.getItem("userApuestaco");

    if(checkingLocalStorage){
      navigate('/groups');
    }
  }, [])

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])

  return (
    <>
      <div className='container'>
        <div className='row'>
          {/* Section 1 de Home */}
          <div className='col-12 col-lg-9 info-register_home offset-md-2 animate__animated animate__fadeInUp'>
            <h2 className='text-center title-home_main_color'>
              Diviértete pronosticando al resultado de diferentes
              eventos deportivos y demuestra que tanto sabes
              de tu deporte favorito.
            </h2>
            <p className='text-muted text-center'>
              Realiza tus predicciones y compite con tus amigos. Fácil e intuitivo.
            </p>
          </div>

          <div className='col-12 btn-register_home text-center'>
            <h3 className='my-4 text-center wow animate__animated animate__fadeInDown' data-wow-duration="2s">
              ¡Inicia hoy mismo registrandote gratis!
            </h3>
            <NavLink to='/register'>
              <button className='btn btn-dark btn-register btn-register-down btn-lg my-3 wow animate__fadeInUp' data-wow-duration="2s">
                Registrarse
              </button>
            </NavLink>
          </div>
          {/* Section 1 de Home */}

          {/* Section 2 de Home */}
          <div className='col-12 my-5 text-center container-img_icons wow animate__zoomIn' data-wow-offset="200" data-wow-duration="1s">
            <div className='row'>

              <div className='col-12 col-md-6 col-lg-3 wow animate__fadeInUp' data-wow-offset="200" data-wow-duration="2s">
                <img className='img-hom_funtions my-4' alt='icon snap' src='https://i.ibb.co/vDnC8Fc/snap.png' />
                <h3 className='title-home_main_color'>Fácil y rapido</h3>
                <p>
                  Simplemente ingresas con tu usuario y
                  seleccionas tu pronóstico para cada
                  encuentro deportivo antes de que de inicio.
                </p>
              </div>

              <div className='col-12 col-md-6 col-lg-3 wow animate__fadeInDown' data-wow-offset="200" data-wow-duration="2s">
                <img className='img-hom_funtions my-4' alt='icon admin person' src='https://i.ibb.co/19Y5dfv/admin-panel.png' />

                <h3 className='title-home_main_color'>Administra tus competencias</h3>
                <p>Puedes crear competencias para que se unan tus amigos. Es totalmente gratis.</p>
              </div>

              <div className='col-12 col-md-6 col-lg-3 wow animate__fadeInUp' data-wow-offset="200" data-wow-duration="2s">
                <img className='img-hom_funtions my-4' alt='icon people laughing' src='https://i.ibb.co/HBZr0w8/laugh.png' />

                <h3 className='title-home_main_color'>Divertido</h3>
                <p>Puedes unirte a un número de competencias ilimitadas.</p>
              </div>

              <div className='col-12 col-md-6 col-lg-3 wow animate__fadeInDown' data-wow-offset="200" data-wow-duration="2s" >
                <img className='img-hom_funtions my-4' alt='icons phone, computer and ipad' src='https://i.ibb.co/Rv6yGXp/responsive.png' />

                <h3 className='title-home_main_color'>En cualquier dispositivo.</h3>
                <p>El sitio funciona desde todos los dispositivos de última generación.</p>
              </div>


            </div>
          </div>
          {/* Section 2 de Home */}

          {/* Section 3 de Home */}
          <div className='col-12 wow animate__fadeInDown' data-wow-offset="200" data-wow-duration="1.5s" >
            <h3 className='my-4 text-center title-home_main_color' id='mainFeatures'>Caracteristicas principales</h3>

            <div className='d-flex align-items-start'>
              <img className='img-prueba me-3' alt='icon shield' src='https://i.ibb.co/9WZcsW7/shield.png' />
              <div>
                <h5 className='title-h5_features'>Plataforma confiable y segura</h5>
                <p>Los jugadores no podrán modificar las apuestas una vez haya iniciado el encuentro deportivo.</p>
              </div>
            </div>

            <div className='d-flex align-items-start'>
              <img className='img-prueba me-3' alt='icon about a paper with todo list' src='https://i.ibb.co/fDjd9cw/to-do-list.png' />
              <div>
                <h5 className='title-h5_features'>Fácil de administrar</h5>
                <p>
                  Ya no es necesario consolidar las apuestas de todos
                  los jugadores en archivos manualmente.
                  En Apuestaco, todo está en la plataforma y se calcula
                  automáticamente la tabla de posiciones.
                </p>
              </div>
            </div>

            <div className='d-flex align-items-start'>
              <img className='img-prueba me-3' alt='icon about help in services' src='https://i.ibb.co/0yg1Kpn/help.png' />
              <div>
                <h5 className='title-h5_features'>Soporte</h5>
                <p>
                  Si tienes problemas, utiliza el menú de soporte
                  comentando tu caso y te brindaremos ayuda.
                </p>
              </div>
            </div>

          </div>
          {/* Section 3 de Home */}

          {/* Section 4 de Home */}
          <div className='col-12 wow animate__fadeInDown' data-wow-offset="200" data-wow-duration="1.5s">

            <div className='mt-5 mb-4'>
              <h3 className='text-center my-4'>¿Cómo funciona?</h3>
              <p className='text-center'>Únete a competencias o crea las tuyas para invitar a tus amigos.</p>
            </div>

            <div className='row'>

              <div className='col-12 col-lg-6'>
                <ul className='ul-li_home'>
                  <li>Ingresa periodicamente a Apuestaco para realizar tus predicciones de cada partido</li>
                  <li>
                    Puedes hacer las predicciones de todos los partidos desde
                    el principio o si prefieres puedes hacerlas a medida que se va llegando la hora del partido.
                  </li>
                  <li>
                    Los partidos se bloquean una vez se llega a su hora de inicio. No se puede
                    apostar ni cambiar las predicciones de los partidos que ya han comenzado.
                  </li>
                </ul>
              </div>

              <div className='col-12 col-lg-6'>
                <ul className='ul-li_home'>
                  <li>Por cada pronóstico acertado, ganas puntos.</li>
                  <li>
                    Visualiza tu rendimiento en la tabla de posiciones de los grupos en los que estés participando.
                    Al finalizar cada partido se actualizan las puntuaciones según los resultados obtenidos por cada jugador.
                  </li>
                  <li>
                    Desde la tabla de posiciones del grupo, puedes ver las predicciones realizadas de tus competidores.
                    Únicamente puedes ver las predicciones de los partidos que han dado inicio.
                  </li>
                </ul>
              </div>

            </div>
          </div>
          {/* Section 4 de Home */}

          {/* Section 5 de Home */}
          <div className='col-12 wow animate__fadeInDown' data-wow-offset="100" data-wow-duration="1.5s">

            <div className='mt-5 mb-4 text-center'>
              <h2 className='title-home_main_color'>¿Qué estas esperando?</h2>
              <NavLink to='/register'>
                <button className='btn btn-dark btn-register btn-register-down btn-lg my-3 wow animate__fadeInUp' data-wow-duration="2s">
                  Registrarse
                </button>
              </NavLink>
            </div>
          </div>
          {/* Section 5 de Home */}
        </div>
      </div>
    </>

  )
}

export { HomeInfoAboutApuestaco }