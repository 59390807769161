import React from 'react';
import { Link } from 'react-router-dom';

const FixtureListItem = ({fixture}) => {

    return (
        <tr>
              <td>{new Date(fixture.date).toLocaleString()}</td>
              <td>
                <img
                  src={fixture.league.logoUrl}
                  alt={fixture.league.name}
                  style={{ width: '30px', marginRight: '10px' }}
                />
                {fixture.league.name}
              </td>
              <td>{fixture.round}</td>
              <td>{fixture.fixtureStatusType}</td>
              <td>
                <img
                  src={fixture.homeTeam.logoUrl}
                  alt={fixture.homeTeam.name}
                  style={{ width: '30px', marginRight: '10px' }}
                />
                {fixture.homeTeam.name}
                {fixture.fullTimeHomeGoals !== null && <span> ({fixture.fullTimeHomeGoals})</span>}
              </td>
              <td>vs</td>
              <td>
                <img
                  src={fixture.awayTeam.logoUrl}
                  alt={fixture.awayTeam.name}
                  style={{ width: '30px', marginRight: '10px' }}
                />                
                {fixture.awayTeam.name}
                {fixture.fullTimeAwayGoals !== null && <span> ({fixture.fullTimeAwayGoals})</span>}                
              </td>
              <td>
              <Link to={`/fixtures/${fixture.uniqueIdentifier}`}>
                Editar
                </Link>
              </td>              
            </tr>
    );
}

export default FixtureListItem;