import React, { useState, useEffect } from "react";
import { SelfFixtureBetsScoreAndWinner } from "../components/SelfFixtureBetsScoreAndWinner";
import { useBets } from "../context/betsContext";

function FixtureBets() {
	const { infoPlayerBets, getInfoPlayerBets, dateMatchs } = useBets();

	const [infoPlayerBetsWithDateChanged, setInfoPlayerBetsWithDateChanged] =
		useState([]);

	useEffect(() => {
		getInfoPlayerBets();
	}, []);

	let infowithValueBetsDefault = [];
	if (dateMatchs && infoPlayerBets.Result) {
		// we will replace the date for the new date formated
		for (let i = 0; i < infoPlayerBets.Result?.length; i++) {
			if (infoPlayerBets.Result) {
				infoPlayerBets.Result[i].fixture.date = dateMatchs[i];
			}
		}

		const initialValueForBets = infoPlayerBets.Result.map((item) => {
			if (item.bet === null) {
				item.bet = {
					fixtureIdentifier: null,
					groupIdentifier: null,
					winnerBet: null,
					homeTeamScore: "",
					awayTeamScore: "",
				};
			} else {
				item.bet = item.bet;
			}
			return item;
		});
		infowithValueBetsDefault = [...initialValueForBets];
	}

	useEffect(() => {
		setInfoPlayerBetsWithDateChanged(infowithValueBetsDefault);
	}, [dateMatchs]);

	return (
		<React.Fragment>
			{/* <AlertUserSessionExpiredToken /> */}
			{!infoPlayerBetsWithDateChanged && (
				<div className='d-flex justify-content-center'>
					<div className='spinner-border' role='status'>
						<span className='visually-hidden'>Loading...</span>
					</div>
				</div>
			)}

			{/* {messageError && <h1>{messageError}</h1>} */}

			{infoPlayerBetsWithDateChanged && (
				<SelfFixtureBetsScoreAndWinner
					infoPlayerBetsWithDateChanged={infoPlayerBetsWithDateChanged}
				/>
			)}
		</React.Fragment>
	);
}

export { FixtureBets };
